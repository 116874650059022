import { FC } from 'react'
import styles from './layout.module.scss'
import { LayoutProps } from './layout.types'

export const Layout: FC<LayoutProps> = ({
  children,
  leftButton,
  rightButton,
  title,
}) => {
  return (
    <div className={styles['layout']}>
      <div className={styles['buttons-block']}>
        <div className={styles['left-button']}>{leftButton}</div>
        <div className={styles['right-button']}>{rightButton}</div>
      </div>
      <div className={styles['title']}>{title}</div>
      <div className={styles['content']}>{children}</div>
    </div>
  )
}
