import Tippy from '@tippyjs/react'
import styles from './tooltip.module.scss'
import { ReactComponent as HintIcon } from '@/shared/icons/hint-icon.svg'
import { FC } from 'react'
import { TooltipProps } from './tooltip.types'

const DefaultTooltipIcon: FC = () => <HintIcon />

export const Tooltip: FC<TooltipProps> = ({
  content = '',
  Icon = DefaultTooltipIcon,
  delay = 80,
  interactive = true,
  interactiveBorder = 20,
  offset = [0, 25],
  arrow = true,
  placement = 'right',
  theme = 'light',
  ...props
}) => {
  return (
    <div>
      <Tippy
        {...props}
        theme={theme}
        content={content}
        interactive={interactive}
        interactiveBorder={interactiveBorder}
        offset={offset}
        delay={delay}
        arrow={arrow}
        placement={placement}
        className={styles['custom-tooltip']}
      >
        <div className={styles['tooltip-icon']}>
          {Icon && typeof Icon === 'function' ? <Icon /> : Icon}
        </div>
      </Tippy>
    </div>
  )
}
