export const luhnAlgorithm = (value: string | number): boolean => {
  let ch = 0
  const num: string = String(value).replace(/\D/g, '')
  const isOdd: boolean = num.length % 2 !== 0

  if ('' === num) return false

  for (let i = 0; i < num.length; i++) {
    let n: number = parseInt(num[i], 10)

    ch += (isOdd ? 1 : 0) === i % 2 && 9 < (n *= 2) ? n - 9 : n
  }

  return ch % 10 === 0
}
