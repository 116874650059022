import { ClipboardEventHandler, useEffect, useRef } from 'react'
import IMask, { FactoryArg, InputMask, InputMaskElement } from 'imask'
import { InputMaskTypes } from '@/shared/interfaces/interfaces'
import {
  monthMask,
  yearMask,
  cvcMask,
  phoneMask,
  cardPatterns,
  cardNumberMasks,
  CardTypes,
} from '@/shared/constants/masks'
import { createSyntheticClipboardEvent } from '@/shared/utils'

type ExcludeTypes = 'email_address' | 'card_number'

const maskTypeMatcher: Record<
  Exclude<InputMaskTypes, ExcludeTypes>,
  Parameters<typeof IMask>[1]
> = {
  phone: phoneMask,
  cvc: cvcMask,
  dateMonth: monthMask,
  dateYear: yearMask,
}

const patternsArray = Object.entries(cardPatterns)

export const getCardType = (cardNumber: string) => {
  if (!!cardNumber) {
    // ищем подходящий паттерн
    for (const [type, pattern] of patternsArray) {
      if (pattern.test(cardNumber)) {
        return type
      }
    }
  }

  return null
}

const getMask = (
  element: InputMaskElement,
  mask: string | null,
): InputMask<FactoryArg> => {
  if (mask && cardNumberMasks[mask as CardTypes]) {
    return IMask(element, {
      mask: cardNumberMasks[mask as CardTypes],
    })
  } else {
    // Если не нашли возвращаем самый простой
    return IMask(element, {
      mask: [
        '0000 0000 0000',
        '0000 0000 0000 0',
        '0000 0000 0000 00',
        '0000 0000 0000 000',
        '0000 0000 0000 0000',
        '0000 0000 0000 0000 0',
        '0000 0000 0000 0000 00',
        '0000 0000 0000 0000 000',
      ],
    })
  }
}

export const useInputMask = (
  inputRef: React.RefObject<HTMLInputElement>,
  maskType: InputMaskTypes,
  {
    onPaste: onPasteProp = () => {},
    onPaymentSystemChange = () => {},
  }: {
    onPaste?: ClipboardEventHandler<HTMLInputElement>
    onPaymentSystemChange?: (system: string | null) => void
  },
) => {
  const currentMask = useRef<ReturnType<typeof IMask> | null>(null)

  useEffect(() => {
    if (inputRef.current && maskType) {
      if (maskType === 'card_number') {
        // Для отслеживания
        let currentSystem: string | null = null

        const onChange = (e: Event) => {
          const system = getCardType((e.target as HTMLInputElement).value)

          if (system !== currentSystem) {
            // Обновляем маску
            currentMask.current?.destroy()
            const mask = getMask(inputRef.current!, system)

            currentSystem = system
            currentMask.current = mask
            currentMask.current.updateValue()
            onPaymentSystemChange(system)

            // TODO
            // При изменении типа и обновлении маски евент не поднимается выше
            // Для решение я этой проблемы создается создается и вызывается новый евент
            inputRef.current!.dispatchEvent(
              new Event('input', { bubbles: true }),
            )
          }
        }

        // Устанавливаем дефолтную маску
        currentMask.current?.destroy()
        const mask = getMask(inputRef.current, null)
        currentMask.current = mask
        onPaymentSystemChange(null)

        inputRef.current.addEventListener('input', onChange)

        return () => {
          inputRef.current?.removeEventListener('input', onChange)
        }
      } else {
        currentMask.current?.destroy()
        currentMask.current = IMask(inputRef.current, maskTypeMatcher[maskType])

        currentMask.current.on('complete', (e) => {
          const currentIdx = (e?.target as HTMLElement).tabIndex

          if (currentIdx) {
            const nextElem = Array.from(
              document.querySelectorAll('input[tabindex]'),
            ).find(
              (elem) => (elem as HTMLElement).tabIndex === currentIdx + 1,
            ) as HTMLElement | undefined

            nextElem?.focus()
          }
        })
      }
    }
  }, [inputRef, maskType, onPaymentSystemChange])

  // TODO Костыльное решение для бага с копипастом значения
  useEffect(() => {
    if (inputRef.current && maskType === 'card_number') {
      const onPaste = (e: ClipboardEvent) => {
        e.stopPropagation()
        e.preventDefault()

        // достем занчение
        const paste = e.clipboardData?.getData('text') || ''
        const pastedDigits = paste.match(/\d+/g)?.join('') || ''

        if (
          inputRef.current &&
          typeof pastedDigits === 'string' &&
          e.clipboardData
        ) {
          // Обновляем маску
          currentMask.current?.destroy()
          const system = getCardType(pastedDigits)
          const mask = getMask(inputRef.current, system)
          currentMask.current = mask

          // Устанавливаем занчение
          inputRef.current.value = pastedDigits
          currentMask.current.updateValue()
          // currentMask.current.value = paste

          // Добавляем формат для получения значения по маске из евента
          const maskedValue = currentMask.current.value
          e.clipboardData.getData = function (format: string) {
            switch (format) {
              case 'masked-text':
                return maskedValue
              default:
                return this.getData(format)
            }
          }
          onPaymentSystemChange(system)

          // Вызываем react евент
          onPasteProp(createSyntheticClipboardEvent<HTMLInputElement>(e))
        }
      }
      inputRef.current.addEventListener('paste', onPaste)
      return () => {
        inputRef.current?.removeEventListener('paste', onPaste)
      }
    }
  }, [
    inputRef.current,
    currentMask.current,
    onPasteProp,
    onPaymentSystemChange,
  ])

  return { currentMask }
}
