export const createSyntheticEvent = <T extends Element, E extends Event>(
  event: E,
): React.SyntheticEvent<T, E> => {
  let isDefaultPrevented = false
  let isPropagationStopped = false
  const preventDefault = () => {
    isDefaultPrevented = true
    event.preventDefault()
  }
  const stopPropagation = () => {
    isPropagationStopped = true
    event.stopPropagation()
  }

  return {
    ...event,
    nativeEvent: event,
    currentTarget: event.currentTarget as EventTarget & T,
    target: event.target as EventTarget & T,
    bubbles: event.bubbles,
    cancelable: event.cancelable,
    defaultPrevented: event.defaultPrevented,
    eventPhase: event.eventPhase,
    isTrusted: event.isTrusted,
    preventDefault,
    isDefaultPrevented: () => isDefaultPrevented,
    stopPropagation,
    isPropagationStopped: () => isPropagationStopped,
    persist: () => {},
    timeStamp: event.timeStamp,
    type: event.type,
  }
}

export const createSyntheticClipboardEvent = <T extends Element>(
  event: ClipboardEvent,
): React.ClipboardEvent<T> => {
  let isDefaultPrevented = false
  let isPropagationStopped = false
  const preventDefault = () => {
    isDefaultPrevented = true
    event.preventDefault()
  }
  const stopPropagation = () => {
    isPropagationStopped = true
    event.stopPropagation()
  }

  return {
    clipboardData: event.clipboardData!,
    nativeEvent: event,
    currentTarget: event.currentTarget as EventTarget & T,
    target: event.target as EventTarget & T,
    bubbles: event.bubbles,
    cancelable: event.cancelable,
    defaultPrevented: event.defaultPrevented,
    eventPhase: event.eventPhase,
    isTrusted: event.isTrusted,
    preventDefault,
    isDefaultPrevented: () => isDefaultPrevented,
    stopPropagation,
    isPropagationStopped: () => isPropagationStopped,
    persist: () => {},
    timeStamp: event.timeStamp,
    type: event.type,
  }
}
