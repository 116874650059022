import {
  PAYMENT_FORM_PAGE,
  PAYMENT_METHOD_PAGE,
  PAYMENT_SERVICE_PAGE,
} from '../constants/routes'
import { SearchParams } from '../constants/search-params'

export const matchPaymentPath = (data: {
  payment_system_id?: number | null
  payment_method_id?: number | null
}) => {
  let pathname = ''
  const searchParams = Object.fromEntries(
    Object.entries({
      [SearchParams.Service]: data.payment_system_id?.toString(),
      [SearchParams.Method]: data.payment_method_id?.toString(),
    }).filter(([_, value]) => value !== undefined && value !== null),
  ) as Record<string, string>

  if (!data.payment_system_id) {
    pathname = PAYMENT_SERVICE_PAGE
  } else if (!data.payment_method_id) {
    pathname = PAYMENT_METHOD_PAGE
  } else {
    pathname = PAYMENT_FORM_PAGE
  }

  return { pathname, searchParams }
}
