import type { FC, ChangeEventHandler } from 'react'
import type { InputProps } from '@/shared/components/input/input.types'

import { useRef, useCallback } from 'react'
import { Input } from '@/shared/components/input'

export const CardHolderInput: FC<InputProps> = ({ onChange, ...props }) => {
  const inputRef = useRef<HTMLInputElement>(null)

  const handleChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    (event) => {
      if (
        inputRef.current &&
        onChange &&
        /^[a-zA-Z\s\.\-']*$/.test(event.target.value) &&
        event.target.value.length <= 26
      ) {
        // значение будется в форме берется из event.target.value
        // где event.target является ссыкой на элемент, зачение которой равно inputRef
        // поэтому мы можем менять это значение через inputRef и оно будет получено в форме
        onChange(event)
      }
    },
    [inputRef, onChange],
  )

  return (
    <Input
      upperCaseOnly
      inputRef={inputRef}
      onChange={handleChange}
      {...props}
    />
  )
}
