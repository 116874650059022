import type { FC } from 'react'

import { useEffect } from 'react'
import { Outlet } from 'react-router'

import { useLanguage } from '@/shared/contexts/language-context'
import {
  ManagementCardsContextProvider,
  useManagementContext,
} from '@/shared/contexts/management-cards-context'
import { LocalStorageKeys } from '@/shared/constants/local-storage'
import {
  CardsTokenContextProvider,
  useCardsTokenContext,
} from '@/shared/contexts/token-context'
import { LazyLoader } from '@/entities/lazy-loader/lazy-loader'

const ManagementRoutes: FC = () => {
  const { loading: languageLoading, changeLanguage } = useLanguage()
  const { loading: managementDataLoading } = useManagementContext()
  const { loading: cardsTokenLoading, data } = useCardsTokenContext()

  const loading = languageLoading || managementDataLoading || cardsTokenLoading

  useEffect(() => {
    if (data?.language_code) {
      changeLanguage(
        data.language_code ?? process.env.REACT_APP_DEFAULT_LANGUAGE,
      ).then(({ language_code }) => {
        localStorage.setItem(LocalStorageKeys.Language, language_code)
        return
      })
    }
  }, [data])

  return loading ? <LazyLoader /> : <Outlet />
}

const ManagementRoutesWrapper: FC = () => {
  return (
    <CardsTokenContextProvider>
      <ManagementCardsContextProvider>
        <ManagementRoutes />
      </ManagementCardsContextProvider>
    </CardsTokenContextProvider>
  )
}

export { ManagementRoutesWrapper as ManagementRoutes }
