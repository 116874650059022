import { ParentEvents } from '../constants/parent-events'

const allowedDomains = (process.env.REACT_APP_TARGET_MESSAGE || '').split(',')

export const postEventToParent = ({
  event,
  data,
}: {
  event: ParentEvents
  data?: unknown
}) => {
  try {
    allowedDomains.forEach((domain) => {
      if (window.self !== window.top && document.referrer.startsWith(domain)) {
        window.parent.postMessage(
          {
            event,
            data,
          },
          domain,
        )
      }
    })
  } catch (err) {
    console.error(err)
  }
}
