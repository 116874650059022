import { IPaymentContext } from '../payment-context.types'

export const defaultValue: IPaymentContext = {
  sessionData: null,
  savedCards: [],
  setSavedCards: () => {},
  savedCard: null,
  setSavedCard: () => {},
  contractId: '',
  setContractId: () => {},
  cardForPayment: null,
  setCardForPayment: () => {},
  eventSource: null,
  fields: {},
  setFields: () => {},
  selectedFields: [],
  method: null,
  selectMethod: () => {},
  selectService: () => {},
  createSession: () => new Promise((res, rej) => rej()),
  loading: true,
  savedCardsLoading: true,
  currencies: [],
  currentCurrency: null,
  currenciesLoading: true,
  selectedFieldsLoading: true,
  disableSessionLeftTimer: () => {},
  closeEventSource: () => {},
}
