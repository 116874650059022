import type { ReactNode } from 'react'

import { ReactComponent as VisaIcon } from '@/shared/icons/color-visa-icon.svg'
import { ReactComponent as MastercardIcon } from '@/shared/icons/color-mastercard-icon.svg'
import { ReactComponent as MirIcon } from '@/shared/icons/color-mir-icon.svg'

export const iconMatcher: Record<string, ReactNode> = {
  mir: <MirIcon />,
  visa: <VisaIcon />,
  mastercard: <MastercardIcon />,
}
