import IMask from 'imask'

export const phoneMask = {
  mask: '+7 (000) 000-00-00',
  lazy: false,
  blocks: {
    0: {
      mask: IMask.MaskedRange,
      placeholderChar: '0',
      from: 0,
      to: 9,
    },
  },
}

const getRegexpByPattern = (patterns: (number | [number, number])[]) => {
  const numbers = []

  for (const pattern of patterns) {
    if (Array.isArray(pattern)) {
      for (let number = pattern[0]; number <= pattern[1]; number++) {
        numbers.push(number)
      }
    } else {
      numbers.push(pattern)
    }
  }

  return new RegExp(`^${numbers.join('|^')}`)
}

export type CardTypes = 'visa' | 'mastercard' | 'mir'

export const cardPatterns: Record<CardTypes, RegExp> = {
  visa: getRegexpByPattern([4]),
  mastercard: getRegexpByPattern([
    [51, 55],
    [2221, 2229],
    [223, 229],
    [23, 26],
    [270, 271],
    2720,
  ]),
  // maestro: getRegexpByPattern([
  //   493698,
  //   [500000, 504174],
  //   [504176, 506698],
  //   [506779, 508999],
  //   [56, 59],
  //   63,
  //   67,
  //   6,
  // ]),
  mir: getRegexpByPattern([[2200, 2204]]),
}

export const cardNumberMasks: Record<CardTypes, string[]> = {
  visa: [
    '0000 0000 0000 0000',
    '0000 0000 0000 0000 00',
    '0000 0000 0000 0000 000',
  ],
  mastercard: ['0000 0000 0000 0000'],
  // maestro: [
  //   '0000 0000 0000',
  //   '0000 0000 0000 0',
  //   '0000 0000 0000 00',
  //   '0000 0000 0000 000',
  //   '0000 0000 0000 0000',
  //   '0000 0000 0000 0000 0',
  //   '0000 0000 0000 0000 00',
  //   '0000 0000 0000 0000 000',
  // ],
  mir: [
    '0000 0000 0000 0000',
    '0000 0000 0000 0000 0',
    '0000 0000 0000 0000 00',
    '0000 0000 0000 0000 000',
  ],
}

export const monthMask = {
  mask: 'MM',
  lazy: true,
  blocks: {
    MM: {
      mask: IMask.MaskedRange,
      placeholderChar: 'M',
      from: 1,
      to: 12,
    },
  },
}

export const yearMask = {
  mask: 'YY',
  lazy: true,
  blocks: {
    YY: {
      mask: IMask.MaskedRange,
      placeholderChar: 'Г',
      from: new Date().getFullYear() % 100,
      //вариант для проверки валидации прошедшей даты: предполагаем, что сейчас 12.2023
      //from: 23,
      to: 99,
    },
  },
}

export const cvcMask = {
  mask: 'CCC',
  lazy: true,
  blocks: {
    CCC: {
      mask: IMask.MaskedRange,
      placeholderChar: '0',
      from: 0,
      to: 999,
    },
  },
}
