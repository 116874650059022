import type { FC } from 'react'

import { useEffect } from 'react'
import { Typography } from '@/shared/components/typography'
import { ReactComponent as PaymentSuccessIcon } from '@/shared/icons/payment-ok-icon.svg'
import { useLanguage } from '@/shared/contexts/language-context'
import { useMediaContext } from '@/shared/contexts/media-context/media-context'
import { postEventToParent } from '@/shared/utils/post-event-po-parent'
import { ParentEvents } from '@/shared/constants/parent-events'
import { Button } from '@/shared/components/button'

import styles from './payment-result.module.scss'
import classNames from 'classnames'

export const PaymentSuccess: FC = () => {
  const { getField } = useLanguage()
  const { isScreenSm } = useMediaContext()

  const onBack = () => {
    postEventToParent({ event: ParentEvents.SuccessBackButtonClick })
    postEventToParent({ event: ParentEvents.ShowIframeSmallScreen })
  }

  useEffect(() => {
    postEventToParent({ event: ParentEvents.SuccessPageOpen })
  }, [])

  return (
    <div className={styles['payment']}>
      <div
        className={classNames(
          styles['payment-body'],
          isScreenSm && styles['payment-body_mobile'],
        )}
      >
        <div className={styles['top-slot']}>
          <PaymentSuccessIcon />
          <Typography
            className={styles['header-text']}
            tag="span"
            fontSize={isScreenSm ? '24' : '35'}
            letterSpacing="micro"
            color="white-100"
          >
            {getField('payment_success_title')}
          </Typography>
        </div>
        <Button
          variant="fill"
          className={classNames(
            styles['button-back'],
            isScreenSm && styles['button-back_mobile'],
          )}
          onClick={onBack}
        >
          {getField('payment_success_back_button')}
        </Button>
      </div>
    </div>
  )
}
