import { FC, useCallback, useEffect, useRef, useState } from 'react'

import { SavedCards } from '@/entities/saved-card'
import { UserData } from '@/widgets/user-data'

import styles from './payment-page.module.scss'
import { PaymentSessionTimer } from '@/entities/payment-session-timer'
import { useLanguage } from '@/shared/contexts/language-context'
import { Typography } from '@/shared/components/typography'
import { useMediaContext } from '@/shared/contexts/media-context/media-context'
import { usePaymentContext } from '@/shared/contexts/payment-context'
import { MANAGEMENT_CARD_PAGE } from '@/shared/constants/routes'
import { SearchParams } from '@/shared/constants/search-params'
import { useNavigate } from 'react-router'
import { usePaymentTokenContext } from '@/shared/contexts/token-context'
import { postEventToParent } from '@/shared/utils/post-event-po-parent'
import { ParentEvents } from '@/shared/constants/parent-events'
import { useSearchParams } from '@/shared/contexts/search-params-context'
import { NewCard } from '@/shared/components/new-card'
import { ReactComponent as DotsGroup } from '@/shared/icons/dots-group.svg'

export const PaymentPage: FC = () => {
  const navigate = useNavigate()
  const { token } = usePaymentTokenContext()
  const { params } = useSearchParams()
  const containerRef = useRef<HTMLDivElement | null>(null)
  const openFromHub = params.hasOwnProperty(SearchParams.OpenFromHub)
    ? params[SearchParams.OpenFromHub] === SearchParams.OpenFromHub
    : false

  const { getField } = useLanguage()
  const { selectedFields, method } = usePaymentContext()
  const [isMoreOpen, setMoreOpen] = useState(false)
  const { isScreenSm } = useMediaContext()
  const { savedCards } = usePaymentContext()

  const toManagement = useCallback(() => {
    if (token) {
      postEventToParent({ event: ParentEvents.ShowIframeFullScreen })
      navigate({
        pathname: MANAGEMENT_CARD_PAGE,
        search: new URLSearchParams(params).toString(),
      })
    }
  }, [token])

  const handleOtherMethods = () => {
    postEventToParent({ event: ParentEvents.MethodsPageOpen })
  }

  useEffect(() => {
    const container = containerRef.current

    if (container) {
      const resizeObserver = new ResizeObserver(() => {
        const height = container.getBoundingClientRect().height
        postEventToParent({ event: ParentEvents.SetHeight, data: { height } })
      })

      resizeObserver.observe(container)

      return () => {
        resizeObserver.unobserve(container)
      }
    }
  }, [])

  return (
    <div className={styles['payment-container']} ref={containerRef}>
      {selectedFields.length ? (
        <div className={styles['payment']}>
          <div className={styles['payment-body']}>
            {process.env.REACT_APP_TYPE === 'mb' && openFromHub && (
              <div className={styles['payment-body_methods']}>
                <NewCard
                  icon={<img src={method?.icon || ''} />}
                  text={method?.name}
                />
                <NewCard
                  onClick={handleOtherMethods}
                  icon={<DotsGroup />}
                  text={getField('payment_method_other_method_title')}
                />
              </div>
            )}
            {process.env.REACT_APP_TYPE === 'pci_dss' && !openFromHub && (
              <div className={styles['header']}>
                <div className={styles['header_title']}>
                  <Typography
                    tag="span"
                    fontSize={isScreenSm ? '20' : '35'}
                    lineHeight="45"
                    letterSpacing="micro"
                    color="white-100"
                  >
                    {getField('payment_method_title')}
                  </Typography>
                </div>

                <PaymentSessionTimer />
              </div>
            )}
            <div className={styles['payment-form']}>
              {process.env.REACT_APP_TYPE === 'pci_dss' && openFromHub && (
                <div className={styles['payment-form_header']}>
                  <Typography
                    className={styles['title']}
                    color="white-100"
                    lineHeight="25"
                    fontSize="21"
                  >
                    {getField('payment_method_subtitle')}
                  </Typography>
                  {!!savedCards.length && (
                    <Typography
                      onClick={toManagement}
                      className={styles['subtitle']}
                      color="base-yellow-orange"
                      lineHeight="15"
                      fontSize="12"
                      letterSpacing={'middle'}
                    >
                      {getField('payment_method_change_btn')}
                    </Typography>
                  )}
                </div>
              )}
              {process.env.REACT_APP_TYPE === 'pci_dss' && (
                <SavedCards
                  isMoreOpen={isMoreOpen}
                  onMoreClose={() => setMoreOpen(false)}
                  onMoreOpen={() => setMoreOpen(true)}
                />
              )}
              <UserData isOpen={!isMoreOpen} />
            </div>
          </div>
        </div>
      ) : (
        <UserData isOpen={!isMoreOpen} />
      )}
    </div>
  )
}
