import {
  PAYMENT_CONFIRM_PAGE,
  PAYMENT_FAILED_PAGE,
  PAYMENT_SUCCESS_PAGE,
} from '@/shared/constants/routes'
import { usePaymentContext } from '@/shared/contexts/payment-context'
import { useSearchParams } from '@/shared/contexts/search-params-context'
import { CustomMessageEvent } from '@/shared/hooks/use-user-data-sse-events'
import { useNavigate } from 'react-router'
import { postEventToParent } from '@/shared/utils/post-event-po-parent'
import { ParentEvents } from '@/shared/constants/parent-events'

export const useUserSSEHandlers = () => {
  const { params } = useSearchParams()
  const navigate = useNavigate()
  const { setContractId } = usePaymentContext()

  const handleContractConfirmationRequiredEvent = (
    event: CustomMessageEvent,
  ) => {
    if (event.parsedData) {
      setContractId(event.parsedData?.data.contract_id)
      if (event.parsedData?.status === 'contract_confirmation_required') {
        postEventToParent({ event: ParentEvents.ShowIframeFullScreen })
        navigate({
          pathname: PAYMENT_CONFIRM_PAGE,
          search: new URLSearchParams(params).toString(),
        })
      }
    }
  }

  const handleContractInvalidEvent = () => {
    navigate({
      pathname: PAYMENT_FAILED_PAGE,
      search: new URLSearchParams(params).toString(),
    })
  }

  const handleCompletedEvent = () => {
    postEventToParent({ event: ParentEvents.ShowIframeFullScreen })
    navigate({
      pathname: PAYMENT_SUCCESS_PAGE,
      search: new URLSearchParams(params).toString(),
    })
  }

  const handleFailedEvent = () => {
    postEventToParent({ event: ParentEvents.ShowIframeFullScreen })
    navigate({
      pathname: PAYMENT_FAILED_PAGE,
      search: new URLSearchParams(params).toString(),
    })
  }

  return {
    handleContractInvalidEvent,
    handleCompletedEvent,
    handleFailedEvent,
    handleContractConfirmationRequiredEvent,
  }
}
