import type { ControllerRenderProps } from 'react-hook-form'
import type { FormValues } from '@/shared/interfaces/interfaces'
import type { UserPaymentMethodPublicWithData } from '@/shared/contexts/payment-context'

// используется для сопоставления полей при оплате по сохраненной карте
export const getFieldValue = <
  Field extends ControllerRenderProps<FormValues, keyof FormValues>,
>(
  field: Field,
  savedCard: UserPaymentMethodPublicWithData | null,
) => {
  if (savedCard) {
    let value = savedCard.data[field.name] ?? ''

    switch (field.name) {
      case 'card_expiry_year':
        if (savedCard.data.card_expiry_year) {
          value = savedCard.data.card_expiry_year.slice(-2)
        }
        break
      case 'card_number':
        value = `\u2022\u2022\u2022\u2022 \u2022\u2022\u2022\u2022 \u2022\u2022\u2022\u2022 ${savedCard.data.card_number_last4}`
        break
      case 'card_csc':
        value = field.value ?? ''
        break
    }

    return value
  }

  return field.value
}
