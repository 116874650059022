// Типы, которые можно пердать как значения для токена
type TokensValue = string | number | null | undefined

// Дефолтно токены – объект
type DefaultTokens = Record<string, TokensValue>

// Достаем названия токенов из строки
// Для удобства подствновки токенов в изначально известные строки
type TokensFromString<Str extends string> =
  Str extends `${string}{${infer PartA}}${infer PartB}`
    ? PartA | TokensFromString<PartB>
    : null
// Убирем null из позможных ключей
type ParsedTokens<Str extends string> = Exclude<TokensFromString<Str>, null>

// Получаем токены для форматной строки
type Tokens<Str extends string | undefined> =
  // если не undefined
  Str extends string
    ? // Провереям, что в строке есть токен
      Str extends `${string}{${string}}${string}`
      ? // Получем все токены
        DefaultTokens & Partial<Record<ParsedTokens<Str>, TokensValue>>
      : // Возвращаем стандартный тип
        DefaultTokens
    : // Возвращаем стандартный тип
      DefaultTokens

/**
 * Функция для подстановке токенов в шаблонную строку
 * @param string Строка с токенами
 * @param tokens Значение токенов
 *
 * @example <caption>Пример додстановки токенов, когда все токены переданы</caption>
 * const testString = 'какая-то строка с {token} {token_value}'
 *
 * formatLocalizationString(testString, {
 *    token: 'шаблонными',
 *    token_value: 'значениями',
 * })
 * // return "какая-то строка с шаблонными значениями"
 *
 * @example <caption>Пример додстановки токенов, когда не все токены переданы</caption>
 * const testString = 'какая-то строка с {token} {token_value}'
 *
 * formatLocalizationString(testString, {
 *    token: 'шаблонными',
 * })
 * // return "какая-то строка с шаблонными "
 *
 * @example <caption>Пример додстановки токенов, когда все токены переданы</caption>
 * const testString = 'какая-то строка с {token} {token_value}'
 *
 * formatLocalizationString(testString, {})
 * // return "какая-то строка с  "
 *
 * @example <caption>Пример додстановки токенов, когда переданы неизвуестные токены</caption>
 * const testString = 'какая-то строка с {token} {token_value}'
 *
 * formatLocalizationString(testString, {
 *    token: 'шаблонными',
 *    token_value: 'значениями',
 *    empty_token: 1
 * })
 * // return "какая-то строка с шаблонными значениями"
 *
 * @returns {string}
 */
export const formatLocalizationString = <Str extends string | undefined>(
  string: Str,
  tokens: Tokens<Str>,
) => {
  try {
    if (string) {
      // Заменяем все значения вида "{format_token}", token – название токена
      return string.replace(/\{(?<token>[^}]*)\}/gm, (_, token) => {
        // Достаем занчение из токенов и если оно null или undefined, то превращаем его в пустую строку
        const value = tokens[token] ?? ''

        // Возвращаем строку для замены
        return value.toString()
      })
    } else {
      // Выкидываем исключения, сообщая откуда передан undefined
      // Так данные локализации приходят не сразу, может быть полезно
      throw new Error('Format string is empty')
    }
  } catch (err) {
    console.error(err)
    return ''
  }
}
