export enum SSEEvents {
  PAYMENT_PENDING = 'consumer/payment_pending',
  PAYMENT_CONFIRMATION_REQUIRED = 'consumer/payment_confirmation_required',
  COMPLETED = 'consumer/completed',
  CONSUMER_FAILED = 'consumer/failed',
  HANDLER_FAILED = 'handler/failed',
  CONTRACT_INVALID = 'handler/contract_invalid',
  CONTRACT_CONFIRMATION_REQUIRED = 'handler/contract_confirmation_required',
}

export const RECONNECT_ATTEMPTS = 4
export const RECONNECT_INTERVAL = 15000
