import type { InputProps } from '@/shared/components/input/input.types'

import { FC, useRef, useState } from 'react'
import { Input } from '@/shared/components/input'
import { useInputMask } from '@/shared/hooks/use-input-mask'

export const CardNumberInput: FC<InputProps> = ({
  onPaste,
  system: systemProp,
  ...props
}) => {
  const inputRef = useRef<HTMLInputElement>(null)

  const [system, setSystem] = useState<string | null>(null)

  useInputMask(inputRef, 'card_number', {
    onPaste,
    onPaymentSystemChange: setSystem,
  })

  return <Input inputRef={inputRef} system={systemProp ?? system} {...props} />
}
