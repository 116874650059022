import { FC, ReactNode } from 'react'
import { SavedCardManagementProps } from './saved-card-management.types'
import { ReactComponent as MirWhiteIcon } from '@/shared/icons/mir-white-icon.svg'
import { ReactComponent as VisaWhiteIcon } from '@/shared/icons/visa-white-icon.svg'
import { ReactComponent as MastercardWhiteIcon } from '@/shared/icons/mastercard-white-icon.svg'
import { ReactComponent as EllipseWhiteIcon } from '@/shared/icons/ellipse-white-icon.svg'
import { ReactComponent as DeleteIcon } from '@/shared/icons/delete-icon.svg'

import clsx from 'clsx'
import styles from './saved-card-management.module.scss'
import { MANAGEMENT_CARDS_PAGE_DATA_ID } from '@/shared/constants/test/data-testid'

export const SavedCardManagement: FC<SavedCardManagementProps> = ({
  isActive = false,
  onConfirmDelete = () => {},
  onDelete = () => {},
  cardNumber,
  confirmText,
  tooltipText,
  paymentSystem,
  className,
  ...props
}) => {
  const containerClasses = clsx(styles['container'], className)

  const iconMatcher: Record<string, ReactNode> = {
    mir: <MirWhiteIcon />,
    visa: <VisaWhiteIcon />,
    mastercard: <MastercardWhiteIcon />,
  }

  return (
    <div className={containerClasses} {...props}>
      <div className={styles['content']}>
        <div className={styles['info-slot']}>
          <div className={styles['info-slot-left']}>
            <div className={styles['info-slot-left-system']}>
              {iconMatcher[paymentSystem as string]}
            </div>
            <div className={styles['info-slot-left-card-info']}>
              <EllipseWhiteIcon />
              {cardNumber}
            </div>
          </div>
          <div>
            <div
              data-testid={MANAGEMENT_CARDS_PAGE_DATA_ID.DELETE_CARD_BTN}
              onClick={onDelete}
              className={styles['info-slot-right-delete']}
            >
              <DeleteIcon />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
