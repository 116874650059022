import type { FC } from 'react'

import { Routes as ReactRoutes, Route } from 'react-router-dom'

import { PaymentConfirmQr } from '@/pages/payment-confirm-qr'
import { PaymentConfirm } from '@/pages/payment-confirm'
import { PaymentMethod } from '@/pages/payment-method'
import { PaymentFailed, PaymentSuccess } from '@/pages/payment-result'
import { PaymentService } from '@/pages/payment-service'
import { TokenPage } from '@/pages/token-page'
import { PaymentPage } from '@/pages/payment-page'
import { PaymentInProcess } from '@/pages/payment-in-process'
import { ManagementCards } from '@/pages/management-cards'
import {
  PAYMENT_CONFIRM_PAGE,
  PAYMENT_FAILED_PAGE,
  PAYMENT_FORM_PAGE,
  PAYMENT_SUCCESS_PAGE,
  PAYMENT_METHOD_PAGE,
  PAYMENT_SERVICE_PAGE,
  TOKEN_PAGE,
  PAYMENT_PATH,
  PAYMENT_PROCESS_PAGE,
  MANAGEMENT_CARD_PAGE,
  MANAGEMENT_PATH,
  PAYMENT_INTERRUPT_PAGE,
  PAYMENT_CONFIRM_QR_PAGE,
} from '@/shared/constants/routes'

import { CustomRedirect } from './custom-redirect'
import { PaymentRoutes } from './protected-routes/payment-routes'
import { ManagementRoutes } from './protected-routes/management-cards-routes'
import { After3DSRoutes } from './protected-routes/after-3ds-routes'
import { PaymentInterrupt } from '@/pages/payment-result/payment-interrupt'

export const Routes: FC = () => {
  return (
    <ReactRoutes>
      <Route path={PAYMENT_PATH} element={<PaymentRoutes />}>
        {/* страница ввода токена */}
        {process.env.REACT_APP_SETTINGS_PAGE && (
          <Route path={TOKEN_PAGE} element={<TokenPage />} />
        )}
        {/* первый шаг с платежной системой */}
        <Route path={PAYMENT_SERVICE_PAGE} element={<PaymentService />} />
        {/* второй шаг с платежным методом */}
        <Route path={PAYMENT_METHOD_PAGE} element={<PaymentMethod />} />
        {/* третий шаг с данными пользователя */}
        <Route path={PAYMENT_FORM_PAGE} element={<PaymentPage />} />
        {/* четвертый шаг с результатом */}
        <Route path={PAYMENT_CONFIRM_PAGE} element={<PaymentConfirm />} />
        <Route path={PAYMENT_CONFIRM_QR_PAGE} element={<PaymentConfirmQr />} />
        {/* <Route path={"*"} element={<Navigate to={TOKEN_PAGE} />} /> */}
      </Route>

      <Route path={MANAGEMENT_PATH} element={<ManagementRoutes />}>
        <Route path={MANAGEMENT_CARD_PAGE} element={<ManagementCards />} />
      </Route>

      <Route path={PAYMENT_PATH} element={<After3DSRoutes />}>
        <Route path={PAYMENT_SUCCESS_PAGE} element={<PaymentSuccess />} />
        <Route path={PAYMENT_FAILED_PAGE} element={<PaymentFailed />} />
        <Route path={PAYMENT_PROCESS_PAGE} element={<PaymentInProcess />} />
        <Route path={PAYMENT_INTERRUPT_PAGE} element={<PaymentInterrupt />} />
      </Route>

      <Route path={'*'} element={<CustomRedirect />} />
      <Route path={''} element={<CustomRedirect />} />
    </ReactRoutes>
  )
}
