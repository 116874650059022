const getMinutes = (seconds: null | number) => {
  return seconds !== null ? Math.floor(seconds / 60) : null
}
const getSeconds = (seconds: null | number) => {
  return seconds !== null ? seconds % 60 : null
}

export const converter = {
  getMinutes,
  getSeconds,
}
