import { FC } from 'react'
import { LoaderProps } from './loader.types'
import { ReactComponent as SpinnerBigYellowIcon } from '../../icons/spinner-large-yellow.svg'
import { ReactComponent as SpinnerBigWhiteIcon } from '../../icons/spinner-large-white.svg'
import { ReactComponent as SpinnerSmallYellowIcon } from '../../icons/spinner-small-yellow.svg'
import { ReactComponent as SpinnerSmallWhiteIcon } from '../../icons/spinner-small-white.svg'
import clsx from 'clsx'
import styles from './loader.module.scss'

export const Loader: FC<LoaderProps> = ({
  size = 'big',
  color = 'yellow',
  className,
  ...props
}) => {
  const loaderClasses = clsx(styles['loader-container'], className)

  return (
    <div className={loaderClasses} {...props}>
      <div className={styles['loader']}>
        {size === 'big' && color === 'yellow' && <SpinnerBigYellowIcon />}
        {size === 'small' && color === 'yellow' && <SpinnerSmallYellowIcon />}
        {size === 'big' && color === 'white' && <SpinnerBigWhiteIcon />}
        {size === 'small' && color === 'white' && <SpinnerSmallWhiteIcon />}
      </div>
    </div>
  )
}
