import { Currency } from '@/shared/api'

export const amountParser = (
  amount: number | undefined,
  currency: Currency | null,
) => {
  if (amount !== undefined && currency) {
    return (amount / currency.ratio_major_to_minor).toFixed(2)
  } else {
    return amount
  }
}
