import type { FC } from 'react'

import { useEffect, useState } from 'react'
import { Outlet } from 'react-router'

import { useLanguage } from '@/shared/contexts/language-context'
import { LocalStorageKeys } from '@/shared/constants/local-storage'
import { LazyLoader } from '@/entities/lazy-loader/lazy-loader'

export const After3DSRoutes: FC = () => {
  const { changeLanguage } = useLanguage()
  const [languageLoading, setLanguageLoading] = useState(true)

  useEffect(() => {
    changeLanguage(
      localStorage.getItem(LocalStorageKeys.Language) ??
        process.env.REACT_APP_DEFAULT_LANGUAGE,
    )
      .then(({ language_code }) => {
        localStorage.setItem(LocalStorageKeys.Language, language_code)
        return
      })
      .finally(() => {
        setLanguageLoading(false)
      })
  }, [])

  return languageLoading ? <LazyLoader /> : <Outlet />
}
