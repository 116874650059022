import type { FC } from 'react'
import type {
  ISearchParamsContext,
  SearchParamsProviderProps,
} from './search-context-params.type'

import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
  useRef,
} from 'react'
import { useSearchParams as useReactSearchParams } from 'react-router-dom'

export const searchParamsDefaultValue: ISearchParamsContext = {
  params: {},
  setParams() {},
}

export const SearchParamsContext = createContext<ISearchParamsContext>(
  searchParamsDefaultValue,
)

// Конекст для того, чтобы параметры всегда были в одном состоянии при использовании в разных компонентах
export const SearchParamsProvider: FC<SearchParamsProviderProps> = ({
  children,
}) => {
  const [params, setParams] = useReactSearchParams()

  // Для удобства использования параметры преобразуются в объект
  const [paramsObject, setParamsObject] = useState(Object.fromEntries(params))
  //   Для хранения актуального значения и использования в сеттере, чтобы не нужно было добавлять его в зависимости хуков
  const paramsObjectRef = useRef(paramsObject)

  const setParamsByObject = useCallback<ISearchParamsContext['setParams']>(
    (newParams) => {
      const params = Object.fromEntries(
        Object.entries(
          typeof newParams === 'function'
            ? newParams(paramsObjectRef.current)
            : newParams,
        ).filter(([_, value]) => value !== undefined && value !== null),
      ) as Record<string, string>

      setParams(params)
    },
    [],
  )

  useEffect(() => {
    const newParams = Object.fromEntries(params)
    setParamsObject(newParams)
    paramsObjectRef.current = newParams
  }, [params])

  return (
    <SearchParamsContext.Provider
      value={{ params: paramsObject, setParams: setParamsByObject }}
    >
      {children}
    </SearchParamsContext.Provider>
  )
}

export const useSearchParams = () => useContext(SearchParamsContext)
