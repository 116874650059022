import { FC, createContext, useContext, useEffect, useState } from 'react'
import { IMediaContext, MediaContextProviderProps } from './media-context.types'

export const mediaContextDefaultValue: IMediaContext = {
  isScreenSm: false,
}

export const MediaContext = createContext<IMediaContext>(
  mediaContextDefaultValue,
)

export const MediaContextProvider: FC<MediaContextProviderProps> = ({
  children,
}) => {
  const [mediaState, setMediaState] = useState<IMediaContext>(
    mediaContextDefaultValue,
  )

  useEffect(() => {
    const updateValue = <Key extends keyof IMediaContext>(
      key: Key,
      value: IMediaContext[Key],
    ) => {
      setMediaState((prev) => ({
        ...prev,
        [key]: value,
      }))
    }

    const smMedia = window.matchMedia(
      `(max-width: ${window
        .getComputedStyle(document.documentElement)
        .getPropertyValue('--screen-sm')})`,
    )
    smMedia.addEventListener('change', (e) => {
      updateValue('isScreenSm', e.matches)
    })
    updateValue('isScreenSm', smMedia.matches)
  }, [])

  return (
    <MediaContext.Provider value={mediaState}>{children}</MediaContext.Provider>
  )
}

export const useMediaContext = () => useContext(MediaContext)
