import { CustomEventHandler } from '@/shared/hooks/use-user-data-sse-events'
import { PAYMENT_FAILED_PAGE } from '../constants/routes'

const encodeSVGToBase64 = (qr: string) => {
  return 'data:image/svg+xml;base64,' + btoa(qr)
}

export interface PaymentConfirmationRequiredOptions {
  qr?: (code: string) => void
  closeConnection?: () => void
  iframeContainerSelector?: string
}

export const defaultPaymentConfirmationRequiredHandler: (
  callbacks: PaymentConfirmationRequiredOptions,
) => CustomEventHandler = (options) => (event) => {
  const { payload, subtype } = event.parsedData?.data

  if (!payload && !subtype) return

  switch (subtype) {
    case 'visible_redirect_get': {
      options.closeConnection?.()

      window.location.href = payload?.url ?? PAYMENT_FAILED_PAGE

      break
    }

    case 'visible_redirect_post': {
      const form = document.createElement('form')
      form.id = '3dsform'
      form.action = payload?.url
      form.method = 'post'
      form.enctype = 'application/x-www-form-urlencoded'
      // form.enctype = 'text/plain'
      // form.enctype = 'multipart/form-
      for (const [name, value] of Object.entries(
        payload?.payload as Record<string, string>,
      )) {
        const input = document.createElement('input')
        if (payload?.is_hidden === true) {
          input.type = 'hidden'
        }
        input.name = name
        input.value = value
        form.appendChild(input)
      }

      document.body.appendChild(form)
      form.submit()

      break
    }

    case 'hidden_iframe_post': {
      const iframe = document.createElement('iframe')
      iframe.style.display = 'none'
      document.body.appendChild(iframe)

      const form = document.createElement('form')
      form.id = '3dsform'
      form.action = payload?.url
      form.method = 'post'
      form.enctype = 'application/x-www-form-urlencoded'

      for (const [name, value] of Object.entries(
        payload?.payload as Record<string, string>,
      )) {
        const input = document.createElement('input')
        if (payload?.is_hidden === true) {
          input.type = 'hidden'
        }
        input.name = name
        input.value = value
        form.appendChild(input)
      }

      iframe.contentDocument?.body.appendChild(form)
      form.submit()

      break
    }

    case 'visible_iframe_get': {
      const iframe = document.createElement('iframe')
      iframe.src = payload.url
      iframe.style.width = '100%'
      iframe.style.height = '100%'
      iframe.style.position = 'absolute'
      iframe.style.top = '0px'
      iframe.style.left = '0px'

      let element: HTMLElement = document.body

      if (options.iframeContainerSelector) {
        const finedElement = document.querySelector(
          options.iframeContainerSelector,
        )
        if (finedElement) {
          element = finedElement as HTMLElement
        }
      }

      element.append(iframe)
      break
    }

    case 'visible_qr_code': {
      const qrCode = encodeSVGToBase64(payload.payload)
      if (options.qr) options.qr(qrCode)
      break
    }
    default:
      break
  }
}
