import type { FC } from 'react'
import {
  IPaymentSessionTimerContext,
  PaymentSessionTimerContextProviderProps,
} from './payment-session-timer-context.types'

import { createContext, useContext, useEffect, useState, useRef } from 'react'

import { converter } from './utils/converter'

export const paymentSessionTimerDefaultValue: IPaymentSessionTimerContext = {
  sessionTTL: null,
  allSecondsToLeft: null,
  minutesToLeft: null,
  secondsToLeft: null,
}

// Контекст для таймера, чтобы не вызывать лишние ререндеры при изменении времени
export const PaymentSessionContext = createContext(
  paymentSessionTimerDefaultValue,
)

export const PaymentSessionTimerContextProvider: FC<
  PaymentSessionTimerContextProviderProps
> = ({ children, paymentTTL }) => {
  const [seconds, setSeconds] = useState(paymentTTL)
  const [minutesToLeft, setMinutesToLeft] = useState(
    converter.getMinutes(seconds),
  )
  const [secondsToLeft, setSecondsToLeft] = useState(
    converter.getSeconds(seconds),
  )
  const sessionSecondsInterval = useRef<NodeJS.Timeout | null>(null)

  useEffect(() => {
    setSeconds(paymentTTL)

    // Не гарантирует четкого соблюдения времени
    sessionSecondsInterval.current = setInterval(() => {
      // Уменьшаем количество секунд на 1
      setSeconds((prev) => (prev !== null && prev > 0 ? prev - 1 : null))
    }, 1000)

    // Чистим интервал, если помеялось значение ttl
    return () => {
      if (sessionSecondsInterval.current) {
        clearInterval(sessionSecondsInterval.current)
        sessionSecondsInterval.current = null
      }
      setSeconds(null)
    }
  }, [paymentTTL])

  useEffect(() => {
    // Обновляем отображаемое значение
    setMinutesToLeft(converter.getMinutes(seconds))
    setSecondsToLeft(converter.getSeconds(seconds))

    // Если время кончилось, отключаем интервал
    if (seconds === 0 || seconds === null) {
      if (sessionSecondsInterval.current) {
        clearInterval(sessionSecondsInterval.current)
        sessionSecondsInterval.current = null
      }
    }
  }, [seconds])

  return (
    <PaymentSessionContext.Provider
      value={{
        sessionTTL: paymentTTL,
        allSecondsToLeft: seconds,
        minutesToLeft: minutesToLeft,
        secondsToLeft: secondsToLeft,
      }}
    >
      {children}
    </PaymentSessionContext.Provider>
  )
}

export const usePaymentSessionTimerContext = () =>
  useContext(PaymentSessionContext)
