import { FC, useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import { usePaymentContext } from '@/shared/contexts/payment-context'
import { Typography } from '@/shared/components/typography'
import { Loader } from '@/shared/components/loader'
import { NewCard } from '@/shared/components/new-card'
import { PaymentMethodStored, apiService } from '@/shared/api'
import {
  PAYMENT_FAILED_PAGE,
  PAYMENT_FORM_PAGE,
} from '@/shared/constants/routes'
import { SearchParams } from '@/shared/constants/search-params'
import { useSearchParams } from '@/shared/contexts/search-params-context'
import { PaymentSessionTimer } from '@/entities/payment-session-timer'

import styles from './payment-method.module.scss'
import { AxiosError } from 'axios'

// ШАГ 2 выбор платежного метода
export const PaymentMethod: FC = () => {
  const navigate = useNavigate()

  const { params } = useSearchParams()
  const { selectMethod, setFields, sessionData, fields } = usePaymentContext()

  const [paymentMethod, setPaymentMethod] = useState<PaymentMethodStored[]>([])
  const [loading, setLoading] = useState(true)

  // получаем систему
  useEffect(() => {
    if (sessionData?.payment_system_id) {
      setLoading(true)

      const system = sessionData.payment_system_id

      apiService.parametrization
        .getAllPaymentMethodsBySystemIdParametrizationSystemIdMethodsGet(system)
        .then(({ data }) => {
          setPaymentMethod(data)

          Promise.allSettled(
            //получаем все методы
            data
              // TODO Чтобы не дублировать запросы на поля, которые уже есть
              .filter((item) => !fields[item.id])
              .map(
                (item) =>
                  new Promise((resolve, reject) => {
                    apiService.parametrization
                      .getAllFormFieldsBySystemAndMethodIdsParametrizationSystemIdMethodIdFieldsGet(
                        system,
                        item.id,
                      )
                      .then(({ data }) => {
                        setFields((prev) => ({ ...prev, [item.id]: data }))
                        resolve(data)
                      })
                      .catch((err: AxiosError) => {
                        reject(err)
                        const status = err?.response?.status

                        if (status && status >= 400 && status < 500) {
                          window.location.href = PAYMENT_FAILED_PAGE
                        }
                      })
                  }),
              ),
          )
            .catch((err) => {
              console.error(err)
            })
            .finally(() => {})
        })
        .catch((err) => {
          console.error(err)
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }, [sessionData?.payment_system_id])

  // при выборе метода - переходим на страницу с заполнением данных
  const onSubmit = (item: PaymentMethodStored) => {
    selectMethod(item)
    navigate({
      pathname: PAYMENT_FORM_PAGE,
      search: new URLSearchParams({
        ...params,
        [SearchParams.Method]: item.id.toString(),
      }).toString(),
    })
  }

  return (
    <div className={styles['payment']}>
      <div className={styles['payment-header']}>
        <Typography
          tag="span"
          fontSize="35"
          lineHeight="45"
          letterSpacing="micro"
          color="white-100"
          className={styles['header-text']}
        >
          Выберите платежный метод
        </Typography>
        <PaymentSessionTimer />
      </div>
      <div className={styles['payment-body']}>
        {loading ? (
          <Loader size="big" color="yellow" className={styles['loader']} />
        ) : (
          <>
            {Array.isArray(paymentMethod) &&
              paymentMethod?.map(({ id, name, icon }, index) => (
                <div
                  key={id}
                  className={styles[`method-wrapper`]}
                  onClick={() => onSubmit({ id, name, icon })}
                >
                  <NewCard
                    className={styles['method-item']}
                    text={name}
                  ></NewCard>
                </div>
              ))}
          </>
        )}
      </div>
    </div>
  )
}
