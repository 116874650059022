import { useEffect } from 'react'

import { SSEEvents } from '@/shared/constants/sse-events'
import { PaymentConfirmationRequiredOptions } from '../utils/default-sse-handlers'

export type CustomMessageEvent = MessageEvent & {
  parsedData?: Record<string, any>
}

export type CustomEventHandler = (
  serverEvent: CustomMessageEvent,
  callbacks: PaymentConfirmationRequiredOptions,
) => void

interface Options {
  eventSource: EventSource | null
  payment_pending?: CustomEventHandler
  payment_confirmation_required?: CustomEventHandler
  completed?: CustomEventHandler
  consumerFailed?: CustomEventHandler
  handlerFailed?: CustomEventHandler
  contract_invalid?: CustomEventHandler
  contract_confirmation_required?: CustomEventHandler
  withLogs?: boolean
}

const eventDataParser =
  (func: CustomEventHandler) => (serverEvent: CustomMessageEvent) => {
    try {
      serverEvent.parsedData = JSON.parse(serverEvent.data)
    } catch (err) {
      console.error(err)
    }

    func(serverEvent, {})
  }

const withLogsWrapper =
  (eventName: SSEEvents, func: CustomEventHandler) =>
  (serverEvent: CustomMessageEvent) => {
    console.debug(`EVENT: ${eventName}: `, {
      data: serverEvent.parsedData,
      originEvent: serverEvent,
    })
    func(serverEvent, {})
  }

const getHandler = (
  func: CustomEventHandler,
  options: { withLogs?: boolean; eventName: SSEEvents },
) => {
  const parsedDataListener = eventDataParser(func)

  const listener = options.withLogs
    ? withLogsWrapper(options.eventName, parsedDataListener)
    : parsedDataListener

  return listener
}

// TODO получилось очень странно, переделать
export const useUserDataSSEEvents = ({
  eventSource,
  withLogs,
  payment_confirmation_required,
  payment_pending,
  completed,
  contract_confirmation_required,
  contract_invalid,
  consumerFailed,
  handlerFailed,
}: Options) => {
  useEffect(() => {
    if (eventSource && payment_confirmation_required) {
      const listener = getHandler(payment_confirmation_required, {
        withLogs,
        eventName: SSEEvents.PAYMENT_CONFIRMATION_REQUIRED,
      })

      eventSource.addEventListener(
        SSEEvents.PAYMENT_CONFIRMATION_REQUIRED,
        listener,
      )
      return () => {
        eventSource.removeEventListener(
          SSEEvents.PAYMENT_CONFIRMATION_REQUIRED,
          listener,
        )
      }
    }
  }, [eventSource, withLogs, payment_confirmation_required])

  useEffect(() => {
    if (eventSource && payment_pending) {
      const listener = getHandler(payment_pending, {
        withLogs,
        eventName: SSEEvents.PAYMENT_PENDING,
      })

      eventSource.addEventListener(SSEEvents.PAYMENT_PENDING, listener)
      return () => {
        eventSource.removeEventListener(SSEEvents.PAYMENT_PENDING, listener)
      }
    }
  }, [eventSource, withLogs, payment_pending])

  useEffect(() => {
    if (eventSource && completed) {
      const listener = getHandler(completed, {
        withLogs,
        eventName: SSEEvents.COMPLETED,
      })

      eventSource.addEventListener(SSEEvents.COMPLETED, listener)
      return () => {
        eventSource.removeEventListener(SSEEvents.COMPLETED, listener)
      }
    }
  }, [eventSource, withLogs, completed])

  useEffect(() => {
    if (eventSource && contract_confirmation_required) {
      const listener = getHandler(contract_confirmation_required, {
        withLogs,
        eventName: SSEEvents.CONTRACT_CONFIRMATION_REQUIRED,
      })

      eventSource.addEventListener(
        SSEEvents.CONTRACT_CONFIRMATION_REQUIRED,
        listener,
      )
      return () => {
        eventSource.removeEventListener(
          SSEEvents.CONTRACT_CONFIRMATION_REQUIRED,
          listener,
        )
      }
    }
  }, [eventSource, withLogs, contract_confirmation_required])

  useEffect(() => {
    if (eventSource && contract_invalid) {
      const listener = getHandler(contract_invalid, {
        withLogs,
        eventName: SSEEvents.CONTRACT_INVALID,
      })

      eventSource.addEventListener(SSEEvents.CONTRACT_INVALID, listener)
      return () => {
        eventSource.removeEventListener(SSEEvents.CONTRACT_INVALID, listener)
      }
    }
  }, [eventSource, withLogs, contract_invalid])

  useEffect(() => {
    if (eventSource && consumerFailed) {
      const listener = getHandler(consumerFailed, {
        withLogs,
        eventName: SSEEvents.CONSUMER_FAILED,
      })

      eventSource.addEventListener(SSEEvents.CONSUMER_FAILED, listener)
      return () => {
        eventSource.removeEventListener(SSEEvents.CONSUMER_FAILED, listener)
      }
    }
  }, [eventSource, withLogs, consumerFailed])

  useEffect(() => {
    if (eventSource && handlerFailed) {
      const listener = getHandler(handlerFailed, {
        withLogs,
        eventName: SSEEvents.HANDLER_FAILED,
      })

      eventSource.addEventListener(SSEEvents.HANDLER_FAILED, listener)
      return () => {
        eventSource.removeEventListener(SSEEvents.HANDLER_FAILED, listener)
      }
    }
  }, [eventSource, withLogs, handlerFailed])
}
