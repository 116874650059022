import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { App } from '@/app'

import '@/shared/fonts/gotham-ssm.scss'
import './styles.scss'

import './index.module.scss'

const root = ReactDOM.createRoot(document.getElementById('root')!)

if (root) {
  root.render(
    <BrowserRouter>
      <App />
    </BrowserRouter>,
  )
}
