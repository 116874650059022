import type { FC } from 'react'

import { usePaymentSessionTimerContext } from '@/shared/contexts/payment-session-timer-context'
import { useLanguage } from '@/shared/contexts/language-context'
import styles from './payment-session-timer.module.scss'

export const PaymentSessionTimer: FC = () => {
  const { secondsToLeft, minutesToLeft } = usePaymentSessionTimerContext()
  const { getField } = useLanguage()

  return secondsToLeft !== null && minutesToLeft !== null ? (
    <div className={styles['timer']}>
      <div>{getField('session_timer_title')}</div>
      <div className={styles['timer-time']}>
        {`${String(minutesToLeft).padStart(2, '0')}:${String(
          secondsToLeft,
        ).padStart(2, '0')}`}
      </div>
    </div>
  ) : null
}
