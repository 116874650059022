import type { FC } from 'react'
import type { LazyLoaderProps } from './lazy-loader.types'

import { useState, useEffect } from 'react'

import { Loader } from '@/shared/components/loader'

export const LazyLoader: FC<LazyLoaderProps> = ({ delay = 1000, ...props }) => {
  const [isHidden, setHidden] = useState<boolean>(true)

  useEffect(() => {
    const timeout = setTimeout(() => {
      setHidden(false)
    }, delay)

    return () => {
      clearTimeout(timeout)
    }
  }, [])

  return isHidden ? <></> : <Loader {...props} />
}
