import type { InputProps } from '@/shared/components/input/input.types'

import {
  ChangeEventHandler,
  FC,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import { Input } from '@/shared/components/input'

export const DateYearInput: FC<InputProps> = ({
  onPaste,
  onChange,
  onNext,
  ...props
}) => {
  const inputRef = useRef<HTMLInputElement>(null)

  const [value, setValue] = useState<string>('')

  const handleChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    (event) => {
      if (
        inputRef.current &&
        onChange &&
        /^[0-9]*$/.test(event.target.value) &&
        event.target.value.length <= 2
      ) {
        onChange(event)
        setValue(event.target.value)
      }
    },
    [inputRef, onChange],
  )

  useEffect(() => {
    if (value.length === 2) {
      onNext && onNext()
    }
  }, [value])

  return <Input inputRef={inputRef} onChange={handleChange} {...props} />
}
