import { FC, useCallback } from 'react'

import { useState } from 'react'

import { UserPaymentMethodPublic, apiService } from '@/shared/api'
import { UserPaymentMethodPublicWithData } from '@/shared/contexts/payment-context'
import { getCardType } from '@/shared/hooks/use-input-mask'
import { useLanguage } from '@/shared/contexts/language-context'
import { SavedCardManagement } from '@/shared/components/saved-card-management'
import { useCardsTokenContext } from '@/shared/contexts/token-context'
import { useManagementContext } from '@/shared/contexts/management-cards-context'
import { Headers } from '@/shared/constants/headers'
import { PaymentSystemTypes } from '@/shared/components/saved-card-display/saved-card-display.types'
import { ReactComponent as CloseIcon } from '@/shared/icons/close-icon.svg'

import { ReactComponent as CardDobule } from '@/shared/icons/card-double.svg'

import styles from './management-cards.module.scss'
import classnames from 'classnames'
import { Typography } from '@/shared/components/typography'
import { MANAGEMENT_CARDS_PAGE_DATA_ID } from '@/shared/constants/test/data-testid'
import { Layout } from '@/widgets/layout'
import { useNavigate } from 'react-router'
import { useSearchParams } from '@/shared/contexts/search-params-context'
import { PAYMENT_FORM_PAGE } from '@/shared/constants/routes'
import { Button } from '@/shared/components/button'
import { Loader } from '@/shared/components/loader'
import clsx from 'clsx'
import { postEventToParent } from '@/shared/utils/post-event-po-parent'
import { ParentEvents } from '@/shared/constants/parent-events'
import { SearchParams } from '@/shared/constants/search-params'

export const ManagementCards: FC = () => {
  const navigate = useNavigate()

  const { params } = useSearchParams()
  const { getField } = useLanguage()
  const { token } = useCardsTokenContext()
  const { savedCards, getCards, loading } = useManagementContext()

  const [activeCard, setActiveCard] = useState<
    UserPaymentMethodPublic | null | UserPaymentMethodPublicWithData
  >(null)

  // вынести запрос в management-context
  const handleDeleteCard = (itemID: string) => {
    apiService.method
      .deleteMethodMethodMethodIdDelete(itemID, {
        headers: {
          [Headers.Token]: token,
        },
      })
      .then(() => {
        setActiveCard(null)
        getCards()
      })
      .catch((e) => {
        console.error(e)
      })
  }

  const handleChooseCard = (item: UserPaymentMethodPublic) => {
    setActiveCard(item)
  }

  const toMainPage = useCallback(() => {
    postEventToParent({ event: ParentEvents.ShowIframeSmallScreen })
    navigate({
      pathname: PAYMENT_FORM_PAGE,
      search: new URLSearchParams(params).toString(),
    })
  }, [params])

  return (
    <>
      {loading ? (
        <div className={styles['loader']}>
          <Loader />
        </div>
      ) : (
        <div
          className={classnames(styles['management'])}
          onClick={() => setActiveCard(null)}
        >
          <div
            className={clsx({ [styles['management_blackout']]: !!activeCard })}
          ></div>
          <Layout
            title={
              params[SearchParams.OpenFromHub]
                ? getField('cardmanagement_title')
                : undefined
            }
            rightButton={
              params[SearchParams.OpenFromHub] ? (
                <CloseIcon
                  style={{
                    cursor: 'pointer',
                    margin: '-10px',
                    padding: '10px',
                  }}
                  onClick={toMainPage}
                />
              ) : undefined
            }
          >
            <div
              className={styles['body']}
              data-testid={MANAGEMENT_CARDS_PAGE_DATA_ID.CARDS_CONTAINER}
            >
              {savedCards?.length !== 0 ? (
                savedCards?.map((item) => (
                  <SavedCardManagement
                    key={item.id}
                    className={styles['cards']}
                    paymentSystem={
                      getCardType(
                        (item as UserPaymentMethodPublicWithData).data
                          .card_number_first6,
                      ) as PaymentSystemTypes
                    }
                    tooltipText={getField('cards_сontrol_tooltip_name')}
                    isActive={item.id === activeCard?.id}
                    cardNumber={
                      (item as UserPaymentMethodPublicWithData).data
                        .card_number_last4
                    }
                    onDelete={(e) => {
                      e.stopPropagation()
                      handleChooseCard(item)
                    }}
                    data-testid={MANAGEMENT_CARDS_PAGE_DATA_ID.SAVED_CARD}
                  />
                ))
              ) : (
                <div
                  className={styles['no-cards-wrapper']}
                  data-testid={
                    MANAGEMENT_CARDS_PAGE_DATA_ID.NO_SAVED_CARD_CONTAINER
                  }
                >
                  <CardDobule />
                  <Typography
                    className={styles['typography']}
                    color="white-100"
                    lineHeight="25"
                    fontSize="20"
                  >
                    {getField('cardmanagement_nocard_title')}
                  </Typography>
                  <Typography
                    className={styles['typography']}
                    color="white-50"
                    lineHeight="15"
                    fontSize="11"
                  >
                    {getField('cardmanagement_nocard_label')}
                  </Typography>
                </div>
              )}
            </div>
            {activeCard && (
              <div className={styles['confirm-delete']}>
                <Typography
                  className={styles['title']}
                  color="white-100"
                  lineHeight="25"
                  fontSize="21"
                >
                  {getField('cardmanagement_delete_title')}
                </Typography>
                <Typography
                  className={styles['subtitle']}
                  color="white-60"
                  lineHeight="20"
                  fontSize="14"
                  letterSpacing={'middle'}
                >
                  {getField('cardmanagement_delete_subtitle')}
                </Typography>
                <div className={styles['buttons']}>
                  <Button
                    variant={'fill'}
                    danger
                    fullwidth
                    onClick={() =>
                      activeCard && handleDeleteCard(activeCard.id)
                    }
                  >
                    {getField('cardmanagement_delete_btn')}
                  </Button>
                  <Button
                    variant={'outline'}
                    fullwidth
                    onClick={() => setActiveCard(null)}
                  >
                    {getField('cardmanagement_cancel_btn')}
                  </Button>
                </div>
              </div>
            )}
          </Layout>
        </div>
      )}
    </>
  )
}
