export const TOKEN_PAGE_DATA_ID = {
  TOKEN_INPUT: 'token-input',
  MANAGEMENT_BTN: 'management-btn',
  PAYMENT_BTN: 'payment-btn',
}

export const MANAGEMENT_CARDS_PAGE_DATA_ID = {
  NO_SAVED_CARD_CONTAINER: 'no-saved-card-container',
  CARDS_CONTAINER: 'cards-container',
  SAVED_CARD: 'saved-card',
  CONFIRM_DELETE_CARD_BTN: 'confirm-delete-saved-card-btn',
  DELETE_CARD_BTN: 'delete-saved-card-btn',
}

export const PAYMENT_SERVICE_PAGE_DATA_ID = {
  SERVICES_CONTAINER: 'services-container',
}

export const PAYMENT_FORM_PAGE_DATA_ID = {
  DATA_CONTAINER: 'payment-data-container',
  DATA_INPUT: 'payment-data-input',
  EXPAND_SAVED_CARDS_BTN: 'payment-expand-saved-cards-btn',
  EXPANDED_SAVED_CARDS_CONTAINER: 'payment-expanded-saved-cards-container',
  EXPANDED_SAVED_CARD: 'payment-expanded-saved-card',
  SCROLL_SAVED_CARDS_CONTAINER: 'payment-scroll-saved-cards-container',
  SCROLL_SAVED_CARD: 'payment-scroll-saved-card',
}
