import type { Validations } from '@/widgets/user-data/validations'
import { PaymentMethodFieldStored } from '@/shared/api'
import { useCallback, useEffect, useState, useMemo } from 'react'
import { SortedField } from '@/shared/interfaces/interfaces'

export const useSortedFields = (selectedFields: PaymentMethodFieldStored[]) => {
  const [sortedFields, setSortedFields] = useState<
    (SortedField[] | undefined)[]
  >([])
  const [validations, setValidations] = useState<Validations>({})
  const [fieldOrder, setFieldOrder] = useState<
    Record<string, { nextName?: string; prevName?: string; focused: boolean }>
  >({})

  useEffect(() => {
    // сюда будут записываться позиции филдов, у которых есть данные по ним
    const sorted: SortedField[][] = []
    // сюда будут записываться все остальные филды
    const notSorted: SortedField[][] = []
    const regexps: Validations = {}

    for (const field of selectedFields) {
      // TODO временный костыль
      if (!field.name || field.name === 'type') {
        continue
      }
      // достем строку и колонку
      const row = field.field_row_pos
      const column = field.field_col_pos
      //regexps[field.name] = { regexp: field.regex, required: true }
      // Если строки или конолки не, убираем в массив и скипаем
      if (!row || !column) {
        notSorted.push([field as SortedField])
        continue
      }
      // Смотрим, что такая строка есть, если нет, пихаем туда пустой массив
      if (!sorted[row - 1]) {
        sorted[row - 1] = []
      }
      // Устанавливаем в строке на нужный индекс поле
      sorted[row - 1][column - 1] = field as SortedField
    }

    const fields = [...sorted, ...notSorted]

    const order: typeof fieldOrder = {}

    let tabIdx = 1
    let prevField: undefined | string = undefined

    for (let i = 0; i < fields.length; i++) {
      if (Array.isArray(fields[i]) && fields[i].length !== 0) {
        for (let j = 0; j < fields[i].length; j++) {
          if (!!fields[i][j]?.name) {
            order[fields[i][j].name] = {
              prevName: prevField,
              focused: false,
            }
            prevField = fields[i][j].name
            tabIdx += 1
          }

          if (!!fields[i][j]?.tbaIdx) {
            fields[i][j].tbaIdx = tabIdx
          }
        }
      }
    }

    // Устанавливаем порядок заполнения
    Object.entries(order).forEach(([key, value]) => {
      if (value.prevName) {
        order[value.prevName] = {
          ...order[value.prevName],
          nextName: key,
        }
      }
    })

    // Сначала идут отсортированные, потом остальные
    setSortedFields(fields)
    setValidations(regexps)
    setFieldOrder(order)
  }, [selectedFields])

  const focusOnNextField = useCallback(
    (
      currentField: string,
      elementForSearch: HTMLElement = window.document.body,
    ) => {
      if (!fieldOrder[currentField].focused) {
        const nextElement = elementForSearch.querySelector<HTMLInputElement>(
          `input[name=${fieldOrder[currentField].nextName}]`,
        )

        if (nextElement && !nextElement.disabled) {
          nextElement.focus()
          setFieldOrder((prev) => ({
            ...prev,
            [currentField]: {
              ...prev[currentField],
              focused: true,
            },
          }))
        }
      }
    },
    [fieldOrder],
  )

  const defaultValues = useMemo(() => {
    return sortedFields.reduce(
      (acc, curr) => {
        if (curr) {
          return {
            ...acc,
            ...curr.reduce(
              (acc, curr) => {
                acc[curr.name] = ''
                return acc
              },
              {} as Record<string, string>,
            ),
          }
        }
        return acc
      },
      {} as Record<string, string>,
    )
  }, [sortedFields])

  return { sortedFields, validations, focusOnNextField, defaultValues }
}
