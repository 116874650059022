import type { FC } from 'react'
import type { InputPropsWithType, InputType } from './input-wrappers.types'

import { createElement } from 'react'

import * as InputTypes from './input-type'

export const TypedInput: FC<InputPropsWithType> = ({
  type = 'standard',
  ...props
}) => {
  // если типа нет, то выводим стандартный
  if (InputTypes[type]) {
    return createElement(InputTypes[type], props)
  }
  return createElement(InputTypes['standard'], props)
}
export { InputType }
