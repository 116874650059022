import { luhnAlgorithm } from '@/shared/utils/luhn-algorithm'

export interface Validations {
  [key: string]: {
    regexp: string | null
    required: boolean
  }
}

interface IResolverReturnType<Values> {
  values: Values
  errors: {
    [key in keyof Values]?: { message: string | boolean }
  }
}

type ValidationResult = boolean | string

type ValidationExtendedResult =
  | ValidationResult
  | { key: string; value: ValidationResult }[]

export function validate<
  Values extends Record<string, string | null | undefined>,
>(
  values: Values,
  touched: Partial<Record<keyof Values, boolean>>,
  getLocalErrorString: (key: string) => string,
  savedData = false,
): IResolverReturnType<Values> {
  const errors: IResolverReturnType<Values>['errors'] = {}

  const validatorByKey: Record<
    string,
    (value: string) => ValidationExtendedResult
  > = {
    card_expiry_month: (value) => {
      const month = Number(value)

      // Проверяем, что месяц находится в правильном диапазоне
      if (!(month >= 1 && month <= 12)) {
        return getLocalErrorString(
          'payment_data_card_expiry_month_invalid_error',
        )
      }

      // Если год указан
      if (values.card_expiry_year?.length) {
        const currentDate = new Date()

        // И год текущий
        if (
          currentDate.getFullYear() % 100 === Number(values.card_expiry_year) &&
          // Проверяем, что месяц больше или равен текущему
          month < currentDate.getMonth() + 1
        ) {
          return getLocalErrorString(
            'payment_data_card_expiry_year_validity_period_error',
          )
        }
      }

      return false
    },
    card_expiry_year: (value) => {
      if (errors?.card_expiry_month?.message) {
        return true
      }

      const year = Number(value)

      // Если год не указан
      if (Number.isNaN(year)) {
        // Устанавливаем ошибку в поля года и месяца
        return [
          {
            key: 'card_expiry_month',
            value: getLocalErrorString(
              'payment_data_card_expiry_year_validity_period_error',
            ),
          },
          {
            key: 'card_expiry_year',
            value: true,
          },
        ]
      }

      // Если год меньше текущего, устаналиваем ошибку в поле месяца
      if (year < new Date().getFullYear() % 100) {
        return [
          {
            key: 'card_expiry_month',
            value: getLocalErrorString(
              'payment_data_card_expiry_year_validity_period_error',
            ),
          },
          {
            key: 'card_expiry_year',
            value: true,
          },
        ]
      }

      return false
    },
    card_csc: (value) => {
      if (value.length < 3) {
        return getLocalErrorString('payment_data_card_csc_error')
      }

      return false
    },
    card_cardholder: (value) => {
      if (value?.length <= 1) {
        return getLocalErrorString('payment_data_card_cardholder_error')
      }
      return false
    },
    card_number: (value) => {
      if (value.length < 12 || !luhnAlgorithm(value)) {
        return getLocalErrorString('payment_data_card_number_error')
      }
      return false
    },
    email_address: (value) => {
      if (!value.match(/^.+@.+\..+$/)) return 'invalid email'
      return false
    },
  }

  Object.keys(values)?.forEach((key) => {
    if (!touched[key]) {
      return
    }

    // TODO Костыль
    if (savedData && key !== 'card_csc') {
      return
    }

    const value = values[key]

    if (typeof value === 'string' && !value.trim()) {
      errors[key as keyof Values] = {
        // TODO заменить, когда появится токен
        // message: getLocalErrorString('payment_data_required_error'),
        message: true,
      }
      return
    }

    if (validatorByKey[key]) {
      const result = validatorByKey[key](value as string)

      if (Array.isArray(result)) {
        result.forEach((message) => {
          errors[message.key as keyof Values] = { message: message.value }
        })
      } else {
        errors[key as keyof Values] = { message: result }
      }
    }
  })

  return {
    values,
    errors,
  }
}
