import { SortedField } from '@/shared/interfaces/interfaces'

interface UseFormDisabledOptions {
  values: Partial<Record<string, string>>
  touched: Partial<Record<string, boolean>>
  errors: Partial<Record<string, { message: boolean | string }>>
  checkFields: string[] | null
  sortedFields: (SortedField[] | undefined)[]
}

export const useFormDisabled = ({
  sortedFields,
  values,
  touched,
  checkFields,
  errors,
}: UseFormDisabledOptions): boolean => {
  if (sortedFields.length === 0) return false
  // Если форма не изменялась
  if (!Object.keys(touched).length) return true

  // Если нужно проверить конкретные поля
  if (checkFields) {
    return checkFields.some((key) => !touched[key] || !!errors[key]?.message)
  } else {
    return Object.keys(values).some(
      (key) => !touched[key] || !!errors[key]?.message,
    )
  }
}
