import { CheckboxProps } from './checkbox.types'
import styles from './checkbox.module.scss'
import clsx from 'clsx'
import { FC } from 'react'

export const Checkbox: FC<CheckboxProps> = ({
  label,
  showLabel = true,
  textSize = '13',
  className = '',
  disabled = false,
  name,
  checked,
  ...props
}) => {
  const containerStyles = clsx(styles['checkbox-container'], className)
  const labelStyles = clsx(
    styles['checkbox-label'],
    styles[`checkbox-label_${textSize}`],
    disabled ? styles[`checkbox-label_${textSize}_disabled`] : null,
  )

  return (
    <label className={containerStyles}>
      <input
        name={name}
        checked={checked}
        className={styles.checkbox}
        type={'checkbox'}
        disabled={disabled}
        {...props}
      />
      {showLabel && <span className={labelStyles}>{label}</span>}
    </label>
  )
}
