import type { FC } from 'react'

import { useEffect } from 'react'
import { Typography } from '@/shared/components/typography'
import { useLanguage } from '@/shared/contexts/language-context'
import { ReactComponent as PaymentFailedIcon } from '@/shared/icons/break-connection-icon.svg'
import { useMediaContext } from '@/shared/contexts/media-context/media-context'
import { postEventToParent } from '@/shared/utils/post-event-po-parent'
import { ParentEvents } from '@/shared/constants/parent-events'

import styles from './payment-result.module.scss'
import classNames from 'classnames'
import { Button } from '@/shared/components/button'

export const PaymentInterrupt: FC = () => {
  const { getField } = useLanguage()
  const { isScreenSm } = useMediaContext()

  const onBack = () => {
    postEventToParent({ event: ParentEvents.SuccessBackButtonClick })
  }

  useEffect(() => {
    postEventToParent({ event: ParentEvents.InterruptPageOpen })
  }, [])

  return (
    <div className={styles['payment']}>
      <div
        className={classNames(
          styles['payment-body'],
          isScreenSm && styles['payment-body_mobile'],
        )}
      >
        <div className={styles['top-slot']}>
          <div className={styles['top-slot-icon']}>
            <PaymentFailedIcon />
          </div>
          <Typography
            tag="span"
            fontSize={isScreenSm ? '20' : '35'}
            letterSpacing="micro"
            color="white-100"
          >
            {getField('connection_failed_title')}
          </Typography>
        </div>
        <div className={styles['bottom-slot']}>
          <Typography
            tag="span"
            fontSize="15"
            lineHeight="25"
            letterSpacing="small"
            color="white-60"
          >
            {getField('connection_failed_label')}
          </Typography>
        </div>
        <Button
          variant="fill"
          className={classNames(
            styles['button-back'],
            isScreenSm && styles['button-back_mobile'],
          )}
          onClick={onBack}
        >
          {getField('connection_failed_retry_button')}
        </Button>
      </div>
    </div>
  )
}
