/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

/**
 * Contract
 * Enter model for endpoint with example data
 * @example {"amount_currency":"RUB","amount_value":150013,"country_id":1,"merchant_id":1,"order_id":"822a3a17-5d5b-425e-af04-4a99a4d46229","payment_method_id":1,"payment_system_id":1,"save_payment_method":false,"user_id":"f96300f2-a492-4d0f-bb16-1ec9d163bbc3","user_payment_method_data":{"card_cardholder":"IVAN IVANOV","card_csc":"654","card_expiry_month":"10","card_expiry_year":"24","card_number":"1234567890123456","type":"bank_card"}}
 */
export interface ContractRequestWithExample {
  /** Order Id */
  order_id: string
  /** User Id */
  user_id: string
  /**
   * Amount Value
   * @min 0
   */
  amount_value: number
  /** Amount Currency */
  amount_currency: string
  /** User Payment Method Id */
  user_payment_method_id?: string | null
  /** User Payment Method Data */
  user_payment_method_data?: object | ModelBase | null
  /**
   * Save Payment Method
   * @default false
   */
  save_payment_method?: boolean
  /** Payment System Id */
  payment_system_id?: number | null
  /** Payment Method Id */
  payment_method_id?: number | null
  /** Country Id */
  country_id: number
  /** Merchant Id */
  merchant_id: number
  customer?: CustomerInfo | null
}

/** CountryStored */
export interface CountryStored {
  /**
   * Name
   * Name
   */
  name: string
  /**
   * Numeric Code
   * Numeric code of country from ISO 3166 country codes
   */
  numeric_code?: string | null
  /**
   * Icon
   * Image in base64 encoding
   */
  icon?: string | null
  /**
   * Id
   * ID of entity from DB.
   */
  id: number
}

/** Currency */
export interface Currency {
  /** Code */
  code: string
  /** Ratio Major To Minor */
  ratio_major_to_minor: number
}

/** CustomerInfo */
export interface CustomerInfo {
  /** Ip Address */
  ip_address?: string | null
  /** Screen Res W */
  screen_res_w?: number | null
  /** Screen Res H */
  screen_res_h?: number | null
}

/** HTTPValidationError */
export interface HTTPValidationError {
  /** Detail */
  detail?: ValidationError[]
}

/** HealthcheckCallSchema */
export interface HealthcheckCallSchema {
  /** Passed */
  passed: boolean
  /**
   * Elapsed
   * @format duration
   */
  elapsed: string
}

/** HealthcheckResponseSchema */
export interface HealthcheckResponseSchema {
  /**
   * Healthy
   * @default true
   */
  healthy?: boolean
  /** Checks */
  checks?: Record<string, HealthcheckCallSchema>
}

/** LocalizationDataStored */
export interface LocalizationDataStored {
  /**
   * Language Code
   * Language of localization data
   */
  language_code: string
  /**
   * Data
   * Localizaiton data for frontend
   */
  data: object
  /** Id */
  id: number
}

/** ModelBase */
export type ModelBase = object

/** PaymentMethodFieldStored */
export interface PaymentMethodFieldStored {
  /**
   * Name
   * form field name
   */
  name: string
  /**
   * Description
   * form field description
   */
  description?: string | null
  /**
   * Field Width
   * Field width for front customization form
   */
  field_width?: number | null
  /**
   * Field Row Pos
   * Field row position for frontend
   */
  field_row_pos: number
  /**
   * Field Col Pos
   * Field column position for frontend
   */
  field_col_pos: number
  /**
   * Regex
   * regex for field validation
   */
  regex?: string | null
  /**
   * Payment Method Id
   * FK to payment_methods table
   */
  payment_method_id: number
  /**
   * Required For Payment By Saved Method
   * Flag to indicate if the field is used for payment by a previously saved payment method data
   */
  required_for_payment_by_saved_method: boolean
  /**
   * Id
   * ID of PaymentSystemMethodFieldEntity
   */
  id: number
}

/** PaymentMethodStored */
export interface PaymentMethodStored {
  /** Name */
  name: PaymentMethodType
  /**
   * Icon
   * Image in base64 encoding
   */
  icon?: string | null
  /**
   * Id
   * ID of entity from DB.
   */
  id: number
}

/**
 * PaymentMethodToken
 * Response model with token for payment method payload.
 */
export interface PaymentMethodToken {
  /** User Id */
  user_id: string
  /** Language Code */
  language_code: string
}

/** PaymentMethodType */
export type PaymentMethodType = 'bank_card'

/**
 * PaymentSessionResponse
 * Response model with jwt payload and payment session TTL.
 */
export interface PaymentSessionResponse {
  /** Payment Session Ttl In Seconds */
  payment_session_ttl_in_seconds: number
}

/** PaymentSystemStored */
export interface PaymentSystemStored {
  /**
   * Name
   * Name of avaliable payment system
   */
  name: string
  /**
   * Icon
   * Image in base64 encoding
   */
  icon?: string | null
  /**
   * Id
   * ID of entity from DB
   */
  id: number
}

/**
 * PingResponseSchema
 * @default "pong"
 */
export type PingResponseSchema = string

/** RootResponseSchema */
export interface RootResponseSchema {
  /** Name */
  name: string
  /** Version */
  version?: string | null
}

/**
 * TokenResponse
 * Model for response.
 */
export interface TokenResponse {
  /** Order Id */
  order_id: string
  /** User Id */
  user_id: string
  /**
   * Amount Value
   * @min 0
   */
  amount_value: number
  /** Amount Currency */
  amount_currency: string
  /** Payment System Id */
  payment_system_id?: number | null
  /** Payment Method Id */
  payment_method_id?: number | null
  /** Country Id */
  country_id: number
  /** Language Code */
  language_code: string
  /** Merchant Id */
  merchant_id: number
}

/** UserPaymentMethodPublic */
export interface UserPaymentMethodPublic {
  /** Id */
  id: string
  /** Payment Method Id */
  payment_method_id: number
  /** Data */
  data: object
}

/** ValidationError */
export interface ValidationError {
  /** Location */
  loc: (string | number)[]
  /** Message */
  msg: string
  /** Error Type */
  type: string
}

import type {
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  HeadersDefaults,
  ResponseType,
} from 'axios'
import axios from 'axios'

export type QueryParamsType = Record<string | number, any>

export interface FullRequestParams
  extends Omit<AxiosRequestConfig, 'data' | 'params' | 'url' | 'responseType'> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean
  /** request path */
  path: string
  /** content type of request body */
  type?: ContentType
  /** query params */
  query?: QueryParamsType
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseType
  /** request body */
  body?: unknown
}

export type RequestParams = Omit<
  FullRequestParams,
  'body' | 'method' | 'query' | 'path'
>

export interface ApiConfig<SecurityDataType = unknown>
  extends Omit<AxiosRequestConfig, 'data' | 'cancelToken'> {
  securityWorker?: (
    securityData: SecurityDataType | null,
  ) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void
  secure?: boolean
  format?: ResponseType
}

export enum ContentType {
  Json = 'application/json',
  FormData = 'multipart/form-data',
  UrlEncoded = 'application/x-www-form-urlencoded',
  Text = 'text/plain',
}

export class HttpClient<SecurityDataType = unknown> {
  public instance: AxiosInstance
  private securityData: SecurityDataType | null = null
  private securityWorker?: ApiConfig<SecurityDataType>['securityWorker']
  private secure?: boolean
  private format?: ResponseType

  constructor({
    securityWorker,
    secure,
    format,
    ...axiosConfig
  }: ApiConfig<SecurityDataType> = {}) {
    this.instance = axios.create({
      ...axiosConfig,
      baseURL: axiosConfig.baseURL || '/api',
    })
    this.secure = secure
    this.format = format
    this.securityWorker = securityWorker
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data
  }

  protected mergeRequestParams(
    params1: AxiosRequestConfig,
    params2?: AxiosRequestConfig,
  ): AxiosRequestConfig {
    const method = params1.method || (params2 && params2.method)

    return {
      ...this.instance.defaults,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...((method &&
          this.instance.defaults.headers[
            method.toLowerCase() as keyof HeadersDefaults
          ]) ||
          {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    }
  }

  protected stringifyFormItem(formItem: unknown) {
    if (typeof formItem === 'object' && formItem !== null) {
      return JSON.stringify(formItem)
    } else {
      return `${formItem}`
    }
  }

  protected createFormData(input: Record<string, unknown>): FormData {
    return Object.keys(input || {}).reduce((formData, key) => {
      const property = input[key]
      const propertyContent: any[] =
        property instanceof Array ? property : [property]

      for (const formItem of propertyContent) {
        const isFileType = formItem instanceof Blob || formItem instanceof File
        formData.append(
          key,
          isFileType ? formItem : this.stringifyFormItem(formItem),
        )
      }

      return formData
    }, new FormData())
  }

  public request = async <T = any, _E = any>({
    secure,
    path,
    type,
    query,
    format,
    body,
    ...params
  }: FullRequestParams): Promise<AxiosResponse<T>> => {
    const secureParams =
      ((typeof secure === 'boolean' ? secure : this.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {}
    const requestParams = this.mergeRequestParams(params, secureParams)
    const responseFormat = format || this.format || undefined

    if (
      type === ContentType.FormData &&
      body &&
      body !== null &&
      typeof body === 'object'
    ) {
      body = this.createFormData(body as Record<string, unknown>)
    }

    if (
      type === ContentType.Text &&
      body &&
      body !== null &&
      typeof body !== 'string'
    ) {
      body = JSON.stringify(body)
    }

    return this.instance.request({
      ...requestParams,
      headers: {
        ...(requestParams.headers || {}),
        ...(type && type !== ContentType.FormData
          ? { 'Content-Type': type }
          : {}),
      },
      params: query,
      responseType: responseFormat,
      data: body,
      url: path,
    })
  }
}

/**
 * @title pg-api
 * @version 0.0.0
 * @baseUrl /api
 */
export class Api<
  SecurityDataType extends unknown,
> extends HttpClient<SecurityDataType> {
  contract = {
    /**
     * No description
     *
     * @tags contract
     * @name CreateContractContractPost
     * @summary Create Contract
     * @request POST:/contract
     */
    createContractContractPost: (
      data: ContractRequestWithExample,
      params: RequestParams = {},
    ) =>
      this.request<any, void | HTTPValidationError>({
        path: `/contract`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags contract
     * @name ConfirmContractContractConfirmContractIdPost
     * @summary Confirm Contract
     * @request POST:/contract/confirm/{contract_id}
     */
    confirmContractContractConfirmContractIdPost: (
      contractId: string,
      params: RequestParams = {},
    ) =>
      this.request<any, void | HTTPValidationError>({
        path: `/contract/confirm/${contractId}`,
        method: 'POST',
        format: 'json',
        ...params,
      }),
  }
  method = {
    /**
     * No description
     *
     * @tags method
     * @name GetMethodsByUserMethodAllGet
     * @summary Get Methods By User
     * @request GET:/method/all
     */
    getMethodsByUserMethodAllGet: (params: RequestParams = {}) =>
      this.request<UserPaymentMethodPublic[], void | HTTPValidationError>({
        path: `/method/all`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags method
     * @name DeleteMethodMethodMethodIdDelete
     * @summary Delete Method
     * @request DELETE:/method/{method_id}
     */
    deleteMethodMethodMethodIdDelete: (
      methodId: string,
      params: RequestParams = {},
    ) =>
      this.request<any, void | HTTPValidationError>({
        path: `/method/${methodId}`,
        method: 'DELETE',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags method
     * @name DecodeTokenMethodTokenPost
     * @summary Decode Token
     * @request POST:/method/token
     */
    decodeTokenMethodTokenPost: (params: RequestParams = {}) =>
      this.request<PaymentMethodToken, void | HTTPValidationError>({
        path: `/method/token`,
        method: 'POST',
        format: 'json',
        ...params,
      }),
  }
  parametrization = {
    /**
     * @description Get all payment system without payment methods
     *
     * @tags parametrization
     * @name GetAllPaymentSystemsParametrizationSystemsGet
     * @summary Get All Payment Systems
     * @request GET:/parametrization/systems
     */
    getAllPaymentSystemsParametrizationSystemsGet: (
      params: RequestParams = {},
    ) =>
      this.request<PaymentSystemStored[], void>({
        path: `/parametrization/systems`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * @description Get all payment methods like list by payment system id
     *
     * @tags parametrization
     * @name GetAllPaymentMethodsBySystemIdParametrizationSystemIdMethodsGet
     * @summary Get All Payment Methods By System Id
     * @request GET:/parametrization/{system_id}/methods
     */
    getAllPaymentMethodsBySystemIdParametrizationSystemIdMethodsGet: (
      systemId: number,
      params: RequestParams = {},
    ) =>
      this.request<PaymentMethodStored[], void | HTTPValidationError>({
        path: `/parametrization/${systemId}/methods`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * @description Get all fields by payment system id and payment system method id
     *
     * @tags parametrization
     * @name GetAllFormFieldsBySystemAndMethodIdsParametrizationSystemIdMethodIdFieldsGet
     * @summary Get All Form Fields By System And Method Ids
     * @request GET:/parametrization/{system_id}/{method_id}/fields
     */
    getAllFormFieldsBySystemAndMethodIdsParametrizationSystemIdMethodIdFieldsGet:
      (systemId: number, methodId: number, params: RequestParams = {}) =>
        this.request<PaymentMethodFieldStored[], void | HTTPValidationError>({
          path: `/parametrization/${systemId}/${methodId}/fields`,
          method: 'GET',
          format: 'json',
          ...params,
        }),

    /**
     * @description Get all countries
     *
     * @tags parametrization
     * @name GetCountriesParametrizationCountriesGet
     * @summary Get Countries
     * @request GET:/parametrization/countries
     */
    getCountriesParametrizationCountriesGet: (params: RequestParams = {}) =>
      this.request<CountryStored[], void>({
        path: `/parametrization/countries`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * @description Get localization by language
     *
     * @tags parametrization
     * @name GetLocalizationParametrizationLocalizationLanguageCodeGet
     * @summary Get Localization
     * @request GET:/parametrization/localization/{language_code}
     */
    getLocalizationParametrizationLocalizationLanguageCodeGet: (
      languageCode: string,
      params: RequestParams = {},
    ) =>
      this.request<LocalizationDataStored, void | HTTPValidationError>({
        path: `/parametrization/localization/${languageCode}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags parametrization
     * @name GetSupportedCurrenciesParametrizationCurrencyAllGet
     * @summary Get Supported Currencies
     * @request GET:/parametrization/currency/all
     */
    getSupportedCurrenciesParametrizationCurrencyAllGet: (
      params: RequestParams = {},
    ) =>
      this.request<Currency[], void>({
        path: `/parametrization/currency/all`,
        method: 'GET',
        format: 'json',
        ...params,
      }),
  }
  paymentSession = {
    /**
     * No description
     *
     * @tags payment-session
     * @name CreatePaymentSessionPaymentSessionPost
     * @summary Create Payment Session
     * @request POST:/payment_session
     */
    createPaymentSessionPaymentSessionPost: (params: RequestParams = {}) =>
      this.request<PaymentSessionResponse, void | HTTPValidationError>({
        path: `/payment_session`,
        method: 'POST',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags payment-session
     * @name DecodeTokenPaymentSessionTokenPost
     * @summary Decode Token
     * @request POST:/payment_session/token
     */
    decodeTokenPaymentSessionTokenPost: (params: RequestParams = {}) =>
      this.request<TokenResponse, void | HTTPValidationError>({
        path: `/payment_session/token`,
        method: 'POST',
        format: 'json',
        ...params,
      }),
  }
  app = {
    /**
     * No description
     *
     * @tags Health Check
     * @name LambdaAppInfoGet
     * @summary General service information
     * @request GET:/app/info
     */
    lambdaAppInfoGet: (params: RequestParams = {}) =>
      this.request<RootResponseSchema, any>({
        path: `/app/info`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Health Check
     * @name LambdaAppPingGet
     * @summary "Pong" response
     * @request GET:/app/ping
     */
    lambdaAppPingGet: (params: RequestParams = {}) =>
      this.request<PingResponseSchema, any>({
        path: `/app/ping`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * @description partial(func, *args, **keywords) - new function with partial application of the given arguments and keywords.
     *
     * @tags Health Check
     * @name PartialAppHealthGet
     * @summary Call health checks
     * @request GET:/app/health
     */
    partialAppHealthGet: (params: RequestParams = {}) =>
      this.request<HealthcheckResponseSchema, any>({
        path: `/app/health`,
        method: 'GET',
        format: 'json',
        ...params,
      }),
  }
}
