import type { FC } from 'react'

import { useEffect, useCallback } from 'react'
import { useNavigate } from 'react-router'

import { useSearchParams } from '@/shared/contexts/search-params-context'
import { Loader } from '@/shared/components/loader'
import { useSSE } from '@/shared/hooks/use-sse'
import { useUserDataSSEEvents } from '@/shared/hooks/use-user-data-sse-events'
import {
  PAYMENT_FAILED_PAGE,
  PAYMENT_SUCCESS_PAGE,
} from '@/shared/constants/routes'
import { SearchParams } from '@/shared/constants/search-params'
import { usePaymentContext } from '@/shared/contexts/payment-context'

import styles from './payment-in-process.module.scss'

export const PaymentInProcess: FC = () => {
  const navigate = useNavigate()
  const { params } = useSearchParams()
  const { disableSessionLeftTimer } = usePaymentContext()

  const { eventSource, create, close } = useSSE()

  const handleFailedEvent = useCallback(() => {
    disableSessionLeftTimer()
    navigate({
      pathname: PAYMENT_FAILED_PAGE,
    })
  }, [])

  const handleCompletedEvent = useCallback(() => {
    disableSessionLeftTimer()
    navigate({
      pathname: PAYMENT_SUCCESS_PAGE,
    })
  }, [])

  useUserDataSSEEvents({
    withLogs: true,
    eventSource: eventSource,
    consumerFailed: handleFailedEvent,
    completed: handleCompletedEvent,
  })

  useEffect(() => {
    if (params[SearchParams.OrderID]) {
      create(params[SearchParams.OrderID])
    }
  }, [params])

  useEffect(() => {
    return () => {
      close()
    }
  }, [])

  return (
    <div className={styles.wrapper}>
      <Loader />
    </div>
  )
}
