import type { FC } from 'react'

import { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router'

import { PaymentContext } from '@/shared/contexts/payment-context'
import { Typography } from '@/shared/components/typography'
import { NewCard } from '@/shared/components/new-card'
import { PaymentSystemStored, apiService } from '@/shared/api'
import { PAYMENT_METHOD_PAGE } from '@/shared/constants/routes'
import { SearchParams } from '@/shared/constants/search-params'
import { useSearchParams } from '@/shared/contexts/search-params-context'
import { PaymentSessionTimer } from '@/entities/payment-session-timer'

import styles from './payment-service.module.scss'
import { PAYMENT_SERVICE_PAGE_DATA_ID } from '@/shared/constants/test/data-testid'
import { useLanguage } from '@/shared/contexts/language-context'

export const PaymentService: FC = () => {
  const navigate = useNavigate()
  const { getField } = useLanguage()

  const { params } = useSearchParams()
  const { selectService } = useContext(PaymentContext)

  const [paymentSystem, setPaymentSystem] = useState<PaymentSystemStored[]>([])
  const [loading, setLoading] = useState(false)

  //получаем все платежные сервисы и выводим их
  useEffect(() => {
    setLoading(true)
    apiService.parametrization
      .getAllPaymentSystemsParametrizationSystemsGet()
      .then(({ data }) => setPaymentSystem(data))
      .catch((e) => {
        console.error(e)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [])

  // при выборе системы - переходим на страницу с выбором метода
  const onSubmit = (systemId: number) => {
    selectService(systemId)
    navigate({
      pathname: PAYMENT_METHOD_PAGE,
      search: new URLSearchParams({
        ...params,
        [SearchParams.Service]: String(systemId),
      }).toString(),
    })
  }

  return (
    <div className={styles['payment']}>
      <div className={styles['payment-header']}>
        <Typography
          tag="span"
          fontSize="35"
          lineHeight="45"
          letterSpacing="micro"
          color="white-100"
        >
          {getField('payment_service_title')}
        </Typography>
        <PaymentSessionTimer />
      </div>
      <div
        className={styles['payment-body']}
        data-testid={PAYMENT_SERVICE_PAGE_DATA_ID.SERVICES_CONTAINER}
      >
        {Array.isArray(paymentSystem) &&
          paymentSystem?.map(({ id, name, icon }, index) => (
            <div
              className={styles['service-wrapper']}
              key={id}
              onClick={() => onSubmit(id)}
            >
              <NewCard className={styles['service-item']} text={name}></NewCard>
            </div>
          ))}
      </div>
    </div>
  )
}
