import type { FC, KeyboardEventHandler, ChangeEventHandler } from 'react'
import type { InputProps } from '@/shared/components/input/input.types'

import { useCallback, useState, useEffect, useRef } from 'react'
import { Input } from '@/shared/components/input'

export const CvcInput: FC<InputProps> = ({
  onChange,
  value,
  onNext,
  ...props
}) => {
  const inputRef = useRef<HTMLInputElement | null>(null)

  // Звездочки для отображения
  const [valueLength, setValueLength] = useState<number>(value?.length ?? 0)

  const handleChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    (event) => {
      onChange && onChange(event)
      setValueLength(event.target.value.length)
    },
    [onChange],
  )

  // Для предотвращения ввода всего, кроме цифр
  const handleKeyPress: KeyboardEventHandler<HTMLInputElement> = useCallback(
    (event) => {
      if (!/^\d$/g.test(event.key)) {
        event.preventDefault()
        return false
      }
    },
    [onChange],
  )

  useEffect(() => {
    if (valueLength === 3) {
      onNext && onNext()
    }
  }, [valueLength])

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.value = value ?? ''
    }
  }, [value])

  return (
    <Input
      name="cc-csc"
      autoComplete="cc-csc"
      minLength={3}
      maxLength={3}
      pattern=".*\S.*"
      inputMode="numeric"
      id={props.name}
      onChange={handleChange}
      onKeyPress={handleKeyPress}
      inputRef={inputRef}
      {...{
        ...props,
        'x-autocompletetype': 'cc-csc',
      }}
      type="password"
    />
  )
}
