import { BACKEND_SSE } from '@/shared/constants/api'
import { useRef, useState } from 'react'
import { useNavigate } from 'react-router'
import { PAYMENT_INTERRUPT_PAGE } from '../constants/routes'
import { RECONNECT_ATTEMPTS, RECONNECT_INTERVAL } from '../constants/sse-events'

export const useSSE = () => {
  const [eventSource, setEventSource] = useState<EventSource | null>(null)
  const eventSourceRef = useRef<EventSource | null>(null)
  const reconnectAttemptsRef = useRef(0)
  const reconnectTimeoutRef = useRef<NodeJS.Timeout | null>(null)
  const navigate = useNavigate()

  const handleError = () => {
    console.error(
      'Failed to reconnect after 4 attempts. Navigating to the error screen.',
    )
    navigate(PAYMENT_INTERRUPT_PAGE)
  }

  const create = (order: string) => {
    const url = `${BACKEND_SSE}/sse/sse/${order}`

    eventSourceRef.current = new EventSource(url)
    setEventSource(eventSourceRef.current)

    eventSourceRef.current.onopen = () => {
      console.info('SSE connection is open.')
      reconnectAttemptsRef.current = 0

      if (reconnectTimeoutRef.current) {
        clearTimeout(reconnectTimeoutRef.current)
        reconnectTimeoutRef.current = null
      }
    }

    eventSourceRef.current.onerror = (err) => {
      console.error(err, 'SSE connection failed.')
      eventSourceRef.current?.close()
      eventSourceRef.current = null
      setEventSource(eventSourceRef.current)

      if (reconnectAttemptsRef.current < RECONNECT_ATTEMPTS) {
        reconnectAttemptsRef.current += 1

        reconnectTimeoutRef.current = setTimeout(() => {
          create(order)
        }, RECONNECT_INTERVAL)
      } else {
        handleError()
      }
    }
  }

  const close = () => {
    if (reconnectTimeoutRef.current) {
      clearTimeout(reconnectTimeoutRef.current)
      reconnectTimeoutRef.current = null
    }
    eventSourceRef.current?.close()
    eventSourceRef.current = null
    setEventSource(eventSourceRef.current)
    reconnectAttemptsRef.current = 0
  }

  return { create, close, eventSource, eventSourceRef } as const
}
