import { FC } from 'react'
import { SavedCardDisplayProps } from './saved-card-display.types'
import clsx from 'clsx'
import styles from './saved-card-display.module.scss'

export const SavedCardDisplay: FC<SavedCardDisplayProps> = ({
  type = 'visa',
  isActive = false,
  onClick = () => {},
  card_number,
  className,
  ...props
}) => {
  const containerClasses = clsx(
    styles['container'],
    className,
    isActive && styles['container_active'],
  )
  const ellipsClasses = clsx(
    styles['ellips'],
    isActive && styles['ellips_active'],
  )
  const bottomSlotClasses = clsx(
    styles['bottom-slot'],
    styles[`bottom-slot_${type}`],
    isActive && styles[`bottom-slot_${type}_active`],
  )
  const numberClasses = clsx(
    styles['number'],
    isActive && styles['number_active'],
  )

  return (
    <div onClick={onClick} className={containerClasses} {...props}>
      <div className={styles['content']}>
        <div className={styles['top-slot']}>
          <div className={styles['ellipses']}>
            <div className={ellipsClasses}></div>
          </div>
          <div className={numberClasses}>{card_number}</div>
        </div>
        <div className={bottomSlotClasses}></div>
      </div>
    </div>
  )
}
